import {
  FETCHING_DATA,
  FETCHING_DATA_DONE,
  SET_THEME,
  SET_LOADING,
  SET_LOADING_DONE,
} from "../types";
const initialState = {
  fetching: false,
  theme: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_DATA:
      return {
        ...state,
        fetching: true,
      };
    case FETCHING_DATA_DONE:
      return {
        ...state,
        fetching: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_DONE:
      return {
        ...state,
        loading: false,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };

    default:
      return state;
  }
}
