import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import Moment from "react-moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import BatteryInfo from "./components/BatteryInfo";
import Network from "./components/Network";
import { loadUser } from "./redux/actions/authActions";
import { setTheme } from "./redux/actions/globalActions";
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import Router from "./router";
import store from "./redux/store";
import "emoji-mart/css/emoji-mart.css";
import { Wrapper } from "./providers/react-dates";
Moment.globalFormat = "D.MM.YYYY";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: localStorage.getItem("theme") == "false" ? false : true,
    };
  }

  setTheme = (type) => {
    this.setState({ theme: type });
    localStorage.setItem("theme", type);
    store.dispatch(setTheme(type));
  };

  componentDidMount() {
    store.dispatch(loadUser());
    const theme = localStorage.getItem("theme") == "false" ? false : true;
    this.setState({ theme: theme });
  }

  render() {
    const { theme } = this.state;
    return (
      <StyledThemeProvider theme={theme ? lightTheme : darkTheme}>
        <Wrapper>
          <ThemeProvider theme={theme ? lightTheme : darkTheme}>
            <CssBaseline />
            <BatteryInfo />
            <Network />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Provider store={store}>
                <Router theme={theme} setTheme={this.setTheme} />
              </Provider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Wrapper>
      </StyledThemeProvider>
    );
  }
}

ReactDOM.render(<App />, document.querySelector("#root"));
