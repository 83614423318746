import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../redux/actions/usersActions";
import Table from "../../components/Table";
import Moment from "react-moment";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import styled from "styled-components";

const PaymentsChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Payments({ done, count, amount }) {
  return (
    <PaymentsChip done={done}>
      {amount.toFixed(2)} € in {count}
    </PaymentsChip>
  );
}

const DeletedChip = styled.div`
  background: ${(props) => (props.done ? "#fc1c03" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Deleted({ done }) {
  return <DeletedChip done={done}>{done ? "Yes" : "No"}</DeletedChip>;
}

const DeletedChipAdmin = styled.div`
  background: ${(props) => (props.done ? "#fc1c03" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function DeletedAdmin({ done }) {
  return <DeletedChipAdmin done={done}>{done ? "Yes" : "No"}</DeletedChipAdmin>;
}

const OnlineChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Online({ done }) {
  return <OnlineChip done={done}>{done ? "Online" : "Offline"}</OnlineChip>;
}
const VerifiedChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Verified({ done, facebook }) {
  let verified = done;

  if (facebook) {
    verified = true;
  }
  return (
    <VerifiedChip done={facebook ? true : done ? true : false}>
      {verified ? "Yes" : "No"}
    </VerifiedChip>
  );
}

class Team extends Component {
  render() {
    const { users, data, loading } = this.props.users;
    const columns = [
      {
        id: "picture",
        Header: "Bild",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Avatar
            src={props.original.profile ? props.original.profile.picture : ""}
            alt={props.original.profile ? props.original.username : ""}
          />
        ),
      },
      {
        id: "name",
        Header: "Kunde",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Link to={`/client/${props.original._id}`}>
            <strong style={{ textTransform: "lowercase" }}>
              {props.original.profile ? props.original.username : ""}
            </strong>
          </Link>
        ),
      },
      {
        id: "zip",
        Header: "PLZ",
        accessor: (d) => d._id,
        Cell: (props) => <span>{props.original.zip}</span>,
      },

      {
        id: "createdAt",
        Header: "Register Date",
        accessor: (d) => d._id,
        Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
      },

      {
        id: "online",
        Header: "Online",
        accessor: (d) => d._id,
        Cell: (props) => <Online done={props.original.online} />,
      },
      {
        id: "verified",
        Header: "Verified",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Verified
            done={props.original.emailVerified}
            facebook={props.original.facebook}
          />
        ),
      },
      {
        id: "deleted",
        Header: "Deleted",
        accessor: (d) => d._id,
        Cell: (props) => <Deleted done={props.original.deleted} />,
      },
      {
        id: "deletedAdvertisers",
        Header: "Deleted Advertisers",
        accessor: (d) => d._id,
        Cell: (props) => <DeletedAdmin done={props.original.deletedAdmin} />,
      },
      {
        id: "payments",
        Header: "Payments",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Payments
            done={props.original.payments}
            count={props.original.paymentsDone}
            amount={props.original.paymentsAmount}
          />
        ),
      },
    ];

    return (
      <React.Fragment>
        <Table
          data={users}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&type=1&ref=${
              this.props.refId
            }&${queryString}`;
            this.props.getUsers(query);
          }}
          noDataText='No clients'
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
});

export default connect(mapStateToProps, { getUsers })(Team);
