import React from "react";
import styled from "styled-components";

const Actions = styled.div`
  a,
  span {
    color: inherit;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.3s ease;

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #38aecc;
    }

    svg {
      width: 18px;
      height: 18px;
    }
    &.fi {
      transform: scale(1.1);
    }
  }
`;
export default function TableActions(props) {
  return <Actions>{props.children}</Actions>;
}
