import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { DASHBOARD } from "../config";
export default function ImageUpload({
  onImageChange,
  imagePreviewUrl,
  currentPicture,
}) {
  return (
    <Card variant="outlined" style={{ cursor: "pointer" }}>
      <CardActions style={{ width: "100%", cursor: "pointer" }}>
        <React.Fragment>
          <label htmlFor="image" style={{ width: "100%", cursor: "pointer" }}>
            {!imagePreviewUrl && currentPicture ? (
              <CardMedia
                style={{ height: 250, borderRadius: 3 }}
                image={
                  currentPicture
                    ? currentPicture
                    : DASHBOARD + "/images/not-found.png"
                }
              />
            ) : (
              <CardMedia
                style={{ height: 250, borderRadius: 3 }}
                image={
                  imagePreviewUrl
                    ? imagePreviewUrl
                    : DASHBOARD + "/images/not-found.png"
                }
              />
            )}
          </label>
        </React.Fragment>
      </CardActions>
      <input
        title="image"
        name="image"
        id="image"
        onChange={onImageChange}
        type="file"
        style={{ display: "none" }}
      />
    </Card>
  );
}
