import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Header from "../Modal/Header";
import Actions from "../Modal/Actions";
import Content from "../Modal/Content";
import GlobalLoader from "../GlobalLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Switch } from "formik-material-ui";
import { addCoin } from "../../redux/actions/coinsActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    minWidth: 500,
  },
}));

function AddCoinPack(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const _handleSubmit = (values, formikApi) => {
    const data = {
      title: values.title,
      coins: values.coins,
      price: values.price,
      show: values.show,
      payPal: values.payPal,
      sofort: values.sofort,
      callToPay: values.callToPay,
      mobilePay: values.mobilePay,
      payDirekt: values.payDirekt,
      paySafeCard: values.paySafeCard,
      maestroCard: values.maestroCard,
      klarna: values.klarna,
      masterCard: values.masterCard,
      visa: values.visa,
    };
    props.addCoin(data, formikApi).then((data) => {
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Button color='primary' variant='contained' onClick={handleOpen}>
        ADD NEW
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                title: "",
                coins: "",
                price: "",
                show: true,
                payPal: true,
                sofort: true,
                callToPay: true,
                mobilePay: true,
                payDirekt: true,
                paySafeCard: true,
                maestroCard: true,
                klarna: true,
                masterCard: true,
                visa: true,
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().required("Required"),
                coins: Yup.number()
                  .required("Required")
                  .typeError("Please enter a number"),
                price: Yup.number()
                  .required("Required")
                  .typeError("Please enter a number"),
              })}
              onSubmit={_handleSubmit}>
              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <GlobalLoader></GlobalLoader>}
                  <Header title='Add new coin pack' />
                  <Content>
                    <Field
                      component={TextField}
                      type='text'
                      margin='normal'
                      fullWidth
                      id='title'
                      label='Title'
                      name='title'
                      variant='outlined'
                    />
                    <Field
                      component={TextField}
                      margin='normal'
                      fullWidth
                      name='coins'
                      label='Price in coins'
                      type='text'
                      id='coins'
                      variant='outlined'
                    />
                    <Field
                      component={TextField}
                      margin='normal'
                      fullWidth
                      name='price'
                      label='Price in euro'
                      type='text'
                      id='price'
                      variant='outlined'
                    />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: 500,
                      }}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='show'
                            type='checkbox'
                          />
                        }
                        label='Active'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='payPal'
                            type='checkbox'
                          />
                        }
                        label='Pay Pal'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='sofort'
                            type='checkbox'
                          />
                        }
                        label='Sofort'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='callToPay'
                            type='checkbox'
                          />
                        }
                        label='Call2Pay'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='mobilePay'
                            type='checkbox'
                          />
                        }
                        label='Mobile Pay'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='payDirekt'
                            type='checkbox'
                          />
                        }
                        label='Pay Direkt'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='paySafeCard'
                            type='checkbox'
                          />
                        }
                        label='Pay Safe Card'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='maestroCard'
                            type='checkbox'
                          />
                        }
                        label='Maestro Card'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='visa'
                            type='checkbox'
                          />
                        }
                        label='Visa'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='masterCard'
                            type='checkbox'
                          />
                        }
                        label='Master Card'
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            name='klarna'
                            type='checkbox'
                          />
                        }
                        label='Klarna'
                      />
                    </div>
                  </Content>
                  <Actions>
                    <Button
                      variant='contained'
                      onClick={handleClose}
                      style={{ marginRight: 15 }}>
                      Close
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isSubmitting}
                      onClick={submitForm}>
                      Submit
                    </Button>
                  </Actions>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect((state) => state, { addCoin })(AddCoinPack);
