import React from "react";
import { connect } from "react-redux";
import Authorize from "../utils/Authorize";
import Admin from "../components/Dashboard/Admin";
import SuperAdmin from "../components/Dashboard/SuperAdmin";
import Advertiser from "../components/Dashboard/Advertiser";
import Chatter from "../components/Dashboard/Chatter";

function Dashboard(props) {
  return (
    <React.Fragment>
      <Authorize roles={[5,6]}>
        <SuperAdmin />
      </Authorize>
      <Authorize roles={[4]}>
        <Advertiser />
      </Authorize>
    
      <Authorize roles={[3]}>
        <Chatter />
      </Authorize>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Dashboard);
