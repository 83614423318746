import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";

const FilesUploaded = styled.div`
  margin-top: 10px;
  padding: 20px 0;
  padding: 20px;
  border-radius: 5px;
  .info--marker {
    float: right;
    margin-right: 10px;
    cursor: pointer;
    svg {
      transform: scale(1.8);
    }
  }
  h4 {
    float: left;
    font-size: 18px;
  }

  ul {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    list-style: none;

    li {
      display: block;
      width: 100%;
      height: 140px;
      line-height: 140px;
      display: flex;
      padding: 10px;
      transition: 0.5s ease;
      cursor: pointer;
      background: rgba(76, 84, 90, 0.1);
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      .remove--btn {
        text-align: right;
        svg {
          transform: scale(1.2);
        }

        &:hover {
          svg {
            transform: scale(1.4);
          }
        }
      }
      span.size {
        width: 30%;
      }

      &:hover {
        background: rgba(76, 84, 90, 0.2);
      }
    }
  }
`;
const FileUploadWrapper = styled.div`
  .dropzone {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;

    .button {
      margin-top: 20px;
    }

    svg {
      width: 60px;
      height: 60px;
    }
  }
`;
function FileUpload(props) {
  const [myFiles, setMyFiles] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
    props.handleChange([...myFiles, ...acceptedFiles]);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    props.handleChange(newFiles);
  };

  const files = props.data.map((file) => (
    <CSSTransition key={file.path} timeout={500} classNames='move'>
      <li key={file.file.path}>
        <img
          src={file.preview}
          alt={file.file.path}
          style={{ height: 120, width: 120 }}
        />
        <IconButton
          className='remove--btn'
          onClick={removeFile(file.file)}
          style={{ marginRight: 10 }}>
          <AiOutlineDelete></AiOutlineDelete>
        </IconButton>
      </li>
    </CSSTransition>
  ));

  return (
    <Card variant='outlined'>
      <CardActionArea>
        <FileUploadWrapper>
          <div
            {...getRootProps({
              className: `dropzone ${isDragActive ? "active" : ""}`,
            })}>
            <input {...getInputProps()} />
            <FaCloudUploadAlt></FaCloudUploadAlt>
            {isDragActive ? (
              <p style={{ textAlign: "center" }}>Drag the images here...</p>
            ) : (
              <p style={{ textAlign: "center" }}>
                Drag and drop some images hear or click to select!
              </p>
            )}

            <span className='button outline'>Browse images</span>
          </div>
        </FileUploadWrapper>
      </CardActionArea>
      {props.data.length > 0 && (
        <FilesUploaded>
          <div className='head'>
            <h4>Selected Images</h4>
          </div>
          <ul>
            <TransitionGroup>{files}</TransitionGroup>
          </ul>
        </FilesUploaded>
      )}
    </Card>
  );
}

export default FileUpload;
