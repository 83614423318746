export const Gender = [
  {
    key: "male",
    text: "Männlich",
    value: "Male",
  },
  {
    key: "female",
    text: "Weiblich",
    value: "Female",
  },
  {
    key: "other",
    text: "Other",
    value: "Other",
  },
];

export const months = [
  {
    key: 1,
    label: "1",
    value: "1",
  },
  {
    key: 2,
    label: "2",
    value: "2",
  },
  {
    key: 3,
    label: "3",
    value: "3",
  },
  {
    key: 4,
    label: "4",
    value: "4",
  },
  {
    key: 5,
    label: "5",
    value: "5",
  },
  {
    key: 6,
    label: "6",
    value: "6",
  },
  {
    key: 7,
    label: "7",
    value: "7",
  },
  {
    key: 8,
    label: "8",
    value: "8",
  },
  {
    key: 9,
    label: "9",
    value: "9",
  },
  {
    key: 10,
    label: "10",
    value: "10",
  },
  {
    key: 11,
    label: "11",
    value: "11",
  },
  {
    key: 12,
    label: "12",
    value: "12",
  },
];

export default {
  Gender,
  months,
};
