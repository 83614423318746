import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import useSWR from "swr";

import { API } from "../../config";
import NotFound from "../NotFound";
import Loader from "../../components/LoaderPage";
import Images from "../../components/User/Images";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { api } from "../../lib/api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingTop: 10 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Client(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, error } = useSWR(
    `${API}/users/id/${props.match.params.id}`,
    api
  );
  if (error) return <NotFound />;
  if (!data) return <Loader />;
  const userData = data.data.data;
  const sentMessage = data.data.sentMessage;
  const receivedMessages = data.data.receivedMessages;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <AppBar
            position="static"
            style={{
              borderRadius: 5,
              overflow: "hidden",
              background: "#fff",
              color: "#717CBD",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="About" {...a11yProps(0)} />
              <Tab label="Images" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <Table size="small" component={Paper}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Name
                  </TableCell>
                  <TableCell align="right">{userData.profile.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Username
                  </TableCell>
                  <TableCell align="right">{userData.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="right">{userData.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Gender
                  </TableCell>
                  <TableCell align="right">
                    {userData.profile.gender ? userData.profile.gender : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Birthday
                  </TableCell>
                  <TableCell align="right">
                    {userData.profile.birthday ? (
                      <Moment>{userData.profile.birthday}</Moment>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IP
                  </TableCell>
                  <TableCell align="right">
                    {userData.ip ? userData.ip : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Coins
                  </TableCell>
                  <TableCell align="right">{userData.coins}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Sucht
                  </TableCell>
                  <TableCell align="right">
                    {userData.interested ? userData.interested : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Alter
                  </TableCell>
                  <TableCell align="right">
                    {userData.birthday ? userData.birthday : "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Größe (cm)
                  </TableCell>
                  <TableCell align="right">
                    {userData.size ? userData.size : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Haarfarbe
                  </TableCell>
                  <TableCell align="right">
                    {userData.hair ? userData.hair : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Augenfarbe
                  </TableCell>
                  <TableCell align="right">
                    {userData.eye ? userData.eye : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Figur
                  </TableCell>
                  <TableCell align="right">
                    {userData.figure ? userData.figure : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Raucher
                  </TableCell>
                  <TableCell align="right">
                    {userData.smoker ? userData.smoker : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Suche
                  </TableCell>
                  <TableCell align="right">
                    {userData.searching ? userData.searching : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Beziehungsstatus
                  </TableCell>
                  <TableCell align="right">
                    {userData.status ? userData.status : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Kinder
                  </TableCell>
                  <TableCell align="right">
                    {userData.children ? userData.children : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Beruf
                  </TableCell>
                  <TableCell align="right">
                    {userData.job ? userData.job : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sprache
                  </TableCell>
                  <TableCell align="right">
                    {userData.language ? userData.language : "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Country
                  </TableCell>
                  <TableCell align="right">{userData.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    City
                  </TableCell>
                  <TableCell align="right">{userData.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Zip
                  </TableCell>
                  <TableCell align="right">{userData.zip}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Images userId={userData._id} />
          </TabPanel>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper style={{ padding: 15 }}>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/profiles/edit/${userData._id}`}
              fullWidth
            >
              Edit profile
            </Button>
          </Paper>
          <Paper style={{ marginTop: 15, overflow: "hidden" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Register Date
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{userData.createdAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Online
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{userData.updatedAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Online
                  </TableCell>
                  <TableCell align="right">
                    {userData.online ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sent Messages
                  </TableCell>
                  <TableCell align="right">{sentMessage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Received Messages
                  </TableCell>
                  <TableCell align="right">{receivedMessages}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
