import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
const PageHeader = styled(Grid)`
  margin-bottom: 15px;
  h1 {
    margin: 0;
  }

  @media (max-width: 960px) {
    .Mui-button-root {
      width: 100%;
    }
  }
`;

export default function Header({ title, children, size }) {
  return (
    <PageHeader container>
      <Grid
        items
        xs={12}
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <h1>{title}</h1>
        {size ? (
          <Chip label={size} style={{ marginLeft: 15 }} />
        ) : (
          <React.Fragment />
        )}
      </Grid>
      <Grid
        items
        xs={12}
        md={6}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
        {children}
      </Grid>
    </PageHeader>
  );
}
