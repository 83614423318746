import React from "react";
import Switch from "./Switch";

export default function PageTransition({
  location,
  isAuthenticated,
  setTheme,
  theme,
}) {
  return (
    <Switch
      location={location}
      isAuthenticated={isAuthenticated}
      setTheme={setTheme}
      theme={theme}
    />
  );
}
