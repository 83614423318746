import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import MemberAvatar from "../client/MemberAvatar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ChangePassword from "../../components/User/ChangePassword";
import DisableAccount from "../../components/User/DisableAccount";
import Tooltip from "@material-ui/core/Tooltip";
import Actions from "../../components/Table/Actions";
import Filters from "./Filters";
const PaymentsChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Payments({ done, count, amount }) {
  return (
    <PaymentsChip done={done}>
      {amount.toFixed(2)} € in {count}
    </PaymentsChip>
  );
}

const DeletedChipAdmin = styled.div`
  background: ${(props) => (props.done ? "#fc1c03" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function DeletedAdmin({ done }) {
  return <DeletedChipAdmin done={done}>{done ? "Yes" : "No"}</DeletedChipAdmin>;
}

const VerifiedChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Verified({ done, facebook }) {
  let verified = done;

  if (facebook) {
    verified = true;
  }
  return (
    <VerifiedChip done={facebook ? true : done ? true : false}>
      {verified ? "Yes" : "No"}
    </VerifiedChip>
  );
}

export default function BasicTable({ rows, query, setQuery, data }) {
  const getBackgroundColor = (user) => {
    if (user) {
      let color = "transparent";

      if (user.duplicate) {
        color = "rgba(245, 242, 66, 0.2)";
      }
      if (user.deleted) {
        color = "rgba(181, 0, 0, 0.2)";
      }

      if (user.duplicate && user.deleted) {
        color = "rgba(84, 90, 255, 0.2)";
      }

      return color;
    }
    return "transparent";
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bild</TableCell>
            <TableCell>Kunden</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>PLZ</TableCell>
            <TableCell>IP</TableCell>
            <TableCell>Seit</TableCell>
            <TableCell>Coins</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Deleted Advertisers</TableCell>
            <TableCell>Payments</TableCell>
            <TableCell align="right">Referer</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <Filters query={query} setQuery={setQuery} />
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.name}
              style={{ backgroundColor: getBackgroundColor(row) }}
            >
              <TableCell component="th" scope="row">
                <React.Fragment>
                  <Link to={`/client/${row._id}`}>
                    {row.deleted ? (
                      <MemberAvatar
                        src={row.profile.picture}
                        alt={row.profile.name}
                        online={false}
                      />
                    ) : (
                      <MemberAvatar
                        src={row.profile.picture}
                        alt={row.profile.name}
                        online={row.online}
                      />
                    )}
                  </Link>
                </React.Fragment>
              </TableCell>
              <TableCell>
                <React.Fragment>
                  <Link to={`/client/${row._id}`}>
                    {row.online === true ? (
                      <strong>{row.username}</strong>
                    ) : (
                      <span>{row.username}</span>
                    )}
                  </Link>
                </React.Fragment>
              </TableCell>
              <TableCell>
                <span style={{ textTransform: "lowercase" }}>{row.email}</span>
              </TableCell>
              <TableCell>
                <span>{row.zip}</span>
              </TableCell>
              <TableCell>{row.ip}</TableCell>
              <TableCell>
                <Moment format="DD-MM-YYYY HH:mm">{row.createdAt}</Moment>
              </TableCell>
              <TableCell>{row.coins}</TableCell>
              <TableCell>
                <Verified done={row.emailVerified} facebook={row.facebook} />
              </TableCell>
              <TableCell>
                <DeletedAdmin done={row.deletedAdmin} />
              </TableCell>
              <TableCell>
                <Payments
                  done={row.payments}
                  count={row.paymentsDone}
                  amount={row.paymentsAmount}
                />
              </TableCell>
              <TableCell align="right">
                <React.Fragment>
                  {row?.referer ? (
                    <Link to={`/advertiser/${row?.referer?._id}`}>
                      <strong>{row?.referer?.username}</strong>
                    </Link>
                  ) : (
                    "No referer"
                  )}
                </React.Fragment>
              </TableCell>
              <TableCell align="right">
                <Actions>
                  <Tooltip arrow title="Change password">
                    <ChangePassword userId={row._id} />
                  </Tooltip>
                  <Tooltip arrow title="Delete user from advertisers">
                    <DisableAccount userId={row._id} />
                  </Tooltip>
                </Actions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ padding: 15 }}>
        <Pagination
          count={data?.pagination?.total || 0}
          page={query.page}
          onChange={(e, value) => setQuery({ page: value })}
          variant="outlined"
          color="primary"
        />
      </div>
    </TableContainer>
  );
}
