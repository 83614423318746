import Header from "../../components/Page/Header";
import React from "react";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { getChannels } from "../../redux/actions/messengerActions";
import Avatar from "@material-ui/core/Avatar";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import MemberAvatar from "../client/MemberAvatar";
function Channels(props) {
  const columns = [
    {
      id: "image",
      Header: "Bild",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          <Link to={`/messenger/${props.original._id}`}>
            {props.original.member && (
              <MemberAvatar
                src={props.original.member.profile.picture}
                alt={props.original.member.profile.name}
                online={props.original.member.online}
              />
            )}
          </Link>
        </React.Fragment>
      ),
    },
    {
      id: "name",
      Header: "Kunden",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          <Link to={`/messenger/${props.original._id}`}>
            {props.original.lastMessageSeen === true ? (
              <span>
                {props.original.member && props.original.member.username}
              </span>
            ) : (
              <strong>
                {props.original.member && props.original.member.username}
              </strong>
            )}
          </Link>
        </React.Fragment>
      ),
    },
    {
      id: "in",
      Header: "IN",
      accessor: (d) => d._id,
      Cell: (props) => <React.Fragment>{props.original.in}</React.Fragment>,
    },
    {
      id: "lastAction",
      Header: "Last Action",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.updatedAt}</Moment>,
    },
    {
      id: "out",
      Header: "Out",
      accessor: (d) => d._id,
      Cell: (props) => <React.Fragment>{props.original.out}</React.Fragment>,
    },
    {
      id: "profile",
      Header: "Profile",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.user && props.original.user.username}
        </React.Fragment>
      ),
    },
    {
      id: "profileImage",
      Header: "Profile image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.user && (
            <Avatar
              src={props.original.user.profile.picture}
              alt={props.original.user.profile.name}
            />
          )}
        </React.Fragment>
      ),
    },

    {
      id: "leader",
      Header: "Leader",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.chatterId
            ? props.original.chatterId.username
            : "In waiting room"}
        </React.Fragment>
      ),
    },
    {
      id: "leaderImage",
      Header: "Leader image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.chatterId && (
            <Avatar
              src={props.original.chatterId.profile.picture}
              alt={props.original.chatterId.profile.name}
            />
          )}
        </React.Fragment>
      ),
    },
  ];

  const { channels, data, loading } = props.messenger;
  return (
    <React.Fragment>
      <Header title="Active dialogues" />

      <Table
        data={channels}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          let query = `?page=${state.page + 1}&notwaiting=false&lastMessageSpam=false`;
          props.getChannels(query);
        }}
        noDataText="No active dialogues"
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  messenger: state.messenger,
  auth: state.auth,
});

export default connect(mapStateToProps, { getChannels })(Channels);
