import {
  GET_USERS,
  GET_USER,
  USERS_LOADING,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  FETCHING_DATA,
  FETCHING_DATA_DONE,
  SET_LOADING,
  SET_LOADING_DONE,
} from "../types";
import { returnErrors } from "./errorActions";
import { API } from "../../config";
import cogoToast from "cogo-toast";
import { api } from "../../lib/api";
export const getUsers = (query) => (dispatch, getState) => {
  dispatch(setUsersLoading());
  dispatch({ type: FETCHING_DATA });
  api
    .get(`${API}/users${query}`)
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      dispatch({ type: FETCHING_DATA_DONE });
    })

    .catch((err) => {
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getUser = (id) => (dispatch, getState) => {
  dispatch(setUsersLoading());
  dispatch({
    type: FETCHING_DATA,
  });
  dispatch({ type: SET_LOADING });
  api
    .get(`${API}/users/id/${id}`)
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch({ type: SET_LOADING_DONE });
    })
    .catch((err) => {
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch({ type: SET_LOADING_DONE });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addUser = (user, formikApi, setOpen) => (dispatch, getState) => {
  api
    .post(`${API}/users`, user)
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
      cogoToast.success("User created successfully", {
        position: "top-right",
        heading: "User created",
      });
      formikApi.setSubmitting(false);
      setOpen(false);
    })
    .catch((err) => {
      cogoToast.error("Could not created User", {
        position: "top-right",
        heading: "User not created",
      });
      formikApi.setSubmitting(false);
      setOpen(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addProfile =
  (data, formikApi, history) => (dispatch, getState) => {
    api
      .post(`${API}/users`, data)
      .then((res) => {
        dispatch({
          type: ADD_USER,
          payload: res.data,
        });
        cogoToast.success("User created successfully", {
          position: "top-right",
          heading: "User created",
        });
        formikApi.setSubmitting(false);
        history.push(`/profiles/edit/${res.data.data._id}`);
      })
      .catch((err) => {
        formikApi.setSubmitting(false);
        cogoToast.error("Could not created User", {
          position: "top-right",
          heading: "User not created",
        });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const addContactPerson = (history, id, data) => (dispatch, getState) => {
  api
    .put(`${API}/users/add-contact/${id}`, data)
    .then((res) => {
      cogoToast.success("Contact person addedd successfully", {
        position: "top-right",
        heading: "Contact person added",
      });
      history.push("/users/edit/" + id);
    })
    .catch((err) => {
      cogoToast.error("Could not created contact person", {
        position: "top-right",
        heading: "Contact person not created",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createUserUser = (history, id, data) => (dispatch, getState) => {
  api
    .post(`${API}/users/add-user/${id}`, data)
    .then((res) => {
      cogoToast.success("User user created successfully", {
        position: "top-right",
        heading: "User user created",
      });
      history.push("/users/edit/" + id);
    })
    .catch((err) => {
      cogoToast.error("Could not create user", {
        position: "top-right",
        heading: "Something went wrong",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeContactPerson =
  (history, id, data) => (dispatch, getState) => {
    api
      .put(`${API}/users/remove-contact/${id}`, data)
      .then((res) => {
        cogoToast.success("Contact person removed successfully", {
          position: "top-right",
          heading: "Contact person removed",
        });
        history.push("/users/edit/" + id);
      })
      .catch((err) => {
        cogoToast.error("Could not removed contact person", {
          position: "top-right",
          heading: "Contact person not removed",
        });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const updateUser =
  (id, data, formikApi, setOpen) => (dispatch, getState) => {
    api
      .put(`${API}/users/${id}`, data)
      .then((res) => {
        cogoToast.success("User updated successfully", {
          position: "top-right",
          heading: "User updated",
        });
        setOpen(false);
        formikApi.setSubmitting(false);
      })
      .catch((err) => {
        cogoToast.error("Could not updated User", {
          position: "top-right",
          heading: "User not updated",
        });
        formikApi.setSubmitting(false);
        setOpen(false);
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
export const updateProfile = (history, id, job) => (dispatch, getState) => {
  api
    .put(`${API}/users/${id}`, job)
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: id,
      });
      cogoToast.success("User updated successfully", {
        position: "top-right",
        heading: "User updated",
      });
      history.push("/profiles");
    })
    .catch((err) => {
      cogoToast.error("Could not updated User", {
        position: "top-right",
        heading: "User not updated",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const updatePassword = (id, data, formikApi) => (dispatch, getState) => {
  api
    .put(`${API}/users/change-password/${id}`, data)
    .then((res) => {
      cogoToast.success("User password updated successfully", {
        position: "top-right",
        heading: "Password changed",
      });
      formikApi.setSubmitting(false);
    })
    .catch((err) => {
      cogoToast.error("Could not change password", {
        position: "top-right",
        heading: "Password not changed",
      });
      formikApi.setSubmitting(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteUser = (id) => (dispatch, getState) => {
  api
    .delete(`${API}/users/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
      cogoToast.success("User deleted successfully", {
        position: "top-right",
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      cogoToast.error(err.response.data.error, {
        position: "top-right",
        heading: "Could not delete",
      });
    });
};

export const disableUser = (id, data) => (dispatch, getState) => {
  api
    .put(`${API}/users/${id}`, data)
    .then((res) => {
      cogoToast.success("User deleted from advertisers", {
        position: "top-right",
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      cogoToast.error(err.response.data.error, {
        position: "top-right",
        heading: "Could not delete",
      });
    });
};

export const uploadImage = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    api
      .post(`${API}/users/image/${id}`, data)
      .then((response) => {
        cogoToast.success("Image updated", {
          position: "top-right",
        });
        window.location.reload();
      })
      .catch((error) => {
        cogoToast.error("Something went wrong please try again", {
          position: "top-right",
        });
      });
  };
};

export const uploadAvatar = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING });
    api
      .post(`${API}/users/photo/${id}`, data)
      .then((response) => {
        cogoToast.success("Profile Picture updated", {
          position: "top-right",
        });
        window.location.reload();
      })
      .catch((error) => {
        cogoToast.error("Something went wrong please try again", {
          position: "top-right",
        });
      });
  };
};

export const setUsersLoading = () => {
  return {
    type: USERS_LOADING,
  };
};
