import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import React from "react";
export default function Loader() {
  return (
    <LoadingOverlay
      active={true}
      spinner={<BounceLoader color='#000' />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          margin: "-15px -15px",
          width: "calc(100% + 30px)",
          height: "calc(100vh - 66px)",
        }),
      }}
      fadeSpeed={500}></LoadingOverlay>
  );
}
