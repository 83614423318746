import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

export default function BasicTable({ query, setQuery }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          <TextField
            value={query.name}
            onChange={(e) => setQuery({ name: e.target.value })}
            size="small"
            label="Search"
          />
        </TableCell>
        <TableCell>
          <TextField
            value={query.email}
            onChange={(e) => setQuery({ email: e.target.value })}
            size="small"
            label="Search"
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <TextField
            value={query.ip}
            onChange={(e) => setQuery({ ip: e.target.value })}
            size="small"
            label="Search"
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          <select
            onChange={(event) =>
              setQuery({ emailVerified: event.target.value })
            }
            style={{ width: "100%" }}
            value={query.emailVerified}
          >
            <option value="">Show All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </TableCell>
        <TableCell>
          <select
            onChange={(event) =>
              setQuery({ deletedAdvertisers: event.target.value })
            }
            style={{ width: "100%" }}
            value={query.deletedAdvertisers}
          >
            <option value="">Show All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </TableCell>
        <TableCell>
          <select
            onChange={(event) => setQuery({ payments: event.target.value })}
            style={{ width: "100%" }}
            value={query.payments}
          >
            <option value="">Show All</option>
            <option value="true">With payments</option>
            <option value="false">Without payments</option>
          </select>
        </TableCell>
        <TableCell align="right">Referer</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}
