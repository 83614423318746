/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getOuts } from "../../redux/actions/authActions";
import ChatterInfo from "./ChatterInfo";
const Outs = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 30px;
  cursor: pointer;
`;

const Status = styled.div`
  border: 1px solid
    ${(props) => (props.status === "Connected" ? "green" : "red")};
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.status === "Connected" ? "green" : "red"};
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 10px;
  color: #fff;
`;

function OutsComp({ auth, getOuts, messenger }) {
  const { outs, user } = auth;
  useEffect(() => {
    getOuts(user._id);
  }, [user]);

  const { socketStatus } = messenger;
  if (user.type === 3) {
    return (
      <React.Fragment>
        <Status status={socketStatus}>{socketStatus}</Status>
        <ChatterInfo />
        <Outs>{outs} Outs</Outs>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
}

export default connect((state) => state, { getOuts })(OutsComp);
