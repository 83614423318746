import { GET_ORDERS, ORDERS_LOADING } from "../types";

const initialState = {
  orders: [],
  order: null,
  data: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case ORDERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
