import React from "react";
import { connect } from "react-redux";
import { getGifts, activateGift } from "../redux/actions/giftsActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import AddNew from "../components/Gifts/AddNew";
import Delete from "../components/Gifts/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

function Gifts(props) {
  const handleChange = (id, type) => {
    props.activateGift(id, type);
  };

  const { gifts, data, loading } = props.gift;
  const columns = [
    {
      id: "image",
      Header: "Image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <img
          style={{ height: 40, width: "auto" }}
          src={props.original.image}
          alt=''
        />
      ),
    },
    {
      id: "title",
      Header: "Title",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.title}</strong>,
    },
    {
      id: "coins",
      Header: "Coins",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.coins}</strong>,
    },

    {
      id: "sold",
      Header: "Sold",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.sold ? props.original.sold : "0"}</strong>
      ),
    },

    {
      id: "actions",
      Header: "Actions",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={props.original.show}
                  onChange={() =>
                    handleChange(props.original._id, !props.original.show)
                  }
                  name='active'
                  color='primary'
                />
              }
              label='Active'
            />
          </div>
          <Actions>
            <Delete toDelete={props.original._id} />
          </Actions>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title='Gifts' size={data.total}>
        <AddNew />
      </Header>
      <Table
        data={gifts}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          props.getGifts(query);
        }}
        noDataText='No Gifts'
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  gift: state.gift,
});

export default connect(mapStateToProps, { getGifts, activateGift })(Gifts);
