import {
  GET_COINS,
  GET_COIN,
  ADD_COIN,
  COINS_LOADING,
  DELETE_COIN,
  UPDATE_COIN,
} from "../types";

const initialState = {
  coins: [],
  coin: null,
  data: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COINS:
      return {
        ...state,
        coins: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_COIN:
      return {
        ...state,
        coin: action.payload.data,
      };
    case ADD_COIN:
      return {
        ...state,
        coins: [action.payload.data, ...state.coins],
      };
    case UPDATE_COIN:
      var oldCoins = state.coins.filter(
        (coin) => coin._id !== action.payload.data._id
      );
      return {
        ...state,
        coins: [action.payload.data, ...oldCoins],
      };
    case DELETE_COIN:
      return {
        ...state,
        coins: state.coins.filter((coin) => coin._id !== action.payload),
      };
    case COINS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
