import React from "react";
import useSWR from "swr";
import { API } from "../../config";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { api } from "../../lib/api";
export default function Images({ userId }) {
  const { data, error } = useSWR(`${API}/images?user=${userId}&limit=100`, api);

  if (error)
    return <Paper style={{ padding: 15 }}>Something went wrong!</Paper>;
  if (!data) return <Paper style={{ padding: 15 }}>No images</Paper>;
  let images = data.data.data;

  const handlePrivateChange = (id, type) => {
    const dataImages = {
      private: !type,
    };
    api.put(`${API}/images/${id}`, dataImages).then((res) => {
      window.location.reload();
    });
  };

  const handleDelete = (id, type) => {
    api.delete(`${API}/images/${id}`).then((res) => {
      window.location.reload();
    });
  };

  if (!images) {
    return <Paper style={{ padding: 15 }}>No images</Paper>;
  }
  if (images.length < 1) {
    return <Paper style={{ padding: 15 }}>No images</Paper>;
  }
  return (
    <Paper style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={12} md={4} lg={3}>
            <Paper style={{ height: "100%" }} variant="outlined">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={image.x512}
                  alt={image.x512}
                  style={{ height: "140px", objectFit: "cover" }}
                />
              </div>
              <div
                style={{
                  padding: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={image.private}
                      key={image._id}
                      name={image._id}
                      onChange={(e) =>
                        handlePrivateChange(image._id, image.private)
                      }
                    />
                  }
                  label="Private"
                />
                <IconButton onClick={(e) => handleDelete(image._id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
