/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { V1, V2 } from "../../lib/api";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Deselect from "./Deselect";
import SelectProfile from "./SelectProfile";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";

export default function DataGridDemo() {
  const [info, setInfo] = useState();
  const [withPicture, setWithPicture] = useState(true);
  const [online, setOnline] = useState(true);
  const [offline, setOffline] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [message, setMessage] = useState("");
  const [sendingSpam, setSendingSpam] = useState(false);
  const [spamResponse, setSpamResponse] = useState({});
  const [spamSent, setSpamSent] = useState(false);
  const [withCoins, setWithCoins] = useState(true);
  const [withoutCoins, setWithoutCoins] = useState(true);
  const [selected, setSelected] = useState([]);

  const fetchUsers = () => {
    V1.get("/users/all", {
      params: {
        limit: 20,
        page: 1,
        picture: withPicture,
        online,
        offline,
        min,
        max,
        withCoins,
        withoutCoins,
      },
    }).then((res) => {
      setInfo(res.data);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [online, offline, min, max, withCoins, withoutCoins, withPicture]);

  const sendSpam = () => {
    setSendingSpam(true);
    V2.post("/messages/send-spam", {
      picture: withPicture,
      online,
      offline,
      min,
      max,
      withCoins,
      withoutCoins,
      unselectedUsers: selected.map((item) => item._id),
      message,
      profile: selectedProfile._id,
    }).then((res) => {
      setSendingSpam(false);
      setSpamSent(true);
      setSpamResponse(res.data);
    });
  };

  const removeFromSelected = (username) => {
    setSelected(selected.filter((item) => item.username !== username));
  };
  if (sendingSpam) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          zIndex: 999999,
        }}
      >
        <p style={{ margin: 0, color: "#fff" }}>
          Sending spam to {info?.total} users... please dont close the window!
        </p>
      </div>
    );
  }
  if (spamSent) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          zIndex: 999999,
        }}
      >
        <p style={{ margin: 0, color: "#fff" }}>
          {spamResponse.messagesSent} messages sent
        </p>
        <p style={{ margin: 0, color: "#fff", marginTop: 15 }}>
          You can now close this window
        </p>
      </div>
    );
  }
  return (
    <div>
      <div style={{ height: "calc(100vh - 100px)", width: "100%" }}>
        <h2>Total: {info?.total}</h2>
        <Paper
          variant="outlined"
          style={{
            padding: 18,
            marginBottom: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withPicture}
                  onChange={(e) => setWithPicture(e.target.checked)}
                  name="withPicture"
                  color="primary"
                />
              }
              label="Only with profile picture"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={withCoins}
                  onChange={(e) => setWithCoins(e.target.checked)}
                  name="withCoins"
                  color="primary"
                />
              }
              label="With coins"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={withoutCoins}
                  onChange={(e) => setWithoutCoins(e.target.checked)}
                  name="withoutCoins"
                  color="primary"
                />
              }
              label="Without coins"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={online}
                  onChange={(e) => setOnline(e.target.checked)}
                  name="online"
                  color="primary"
                />
              }
              label="Online"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={offline}
                  onChange={(e) => setOffline(e.target.checked)}
                  name="offline"
                  color="primary"
                />
              }
              label="Offline"
            />
            <TextField
              label="Min"
              variant="outlined"
              size="small"
              style={{ width: 120, marginRight: 10 }}
              type="number"
              onChange={(e) => setMin(e.target.value)}
              value={min}
            />
            <TextField
              label="Max"
              variant="outlined"
              size="small"
              style={{ width: 120 }}
              type="number"
              onChange={(e) => setMax(e.target.value)}
              value={max}
            />
          </div>
        </Paper>

        <Paper
          variant="outlined"
          style={{
            padding: "8px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <TextField
            label="Message"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            style={{ marginRight: 15 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            disabled={!selectedProfile || info?.total > 5000}
            onClick={sendSpam}
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </Paper>

        {info?.total > 5000 && (
          <Alert severity="error" style={{ marginBottom: 15 }}>
            Select less users for spam
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: "1px 24px" }}>
              <h2>Unselect users</h2>
              <Deselect
                selected={selected}
                setSelected={setSelected}
                filters={{
                  picture: withPicture,
                  online,
                  offline,
                  min,
                  max,
                  withCoins,
                  withoutCoins,
                }}
              />
            </Paper>
            {selected.length !== 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 60 }}></TableCell>
                    <TableCell style={{ width: 80 }}>Avatar</TableCell>
                    <TableCell>Username</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selected.map((row) => (
                    <TableRow key={row.username}>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={(e) => removeFromSelected(row.username)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Avatar
                          src={row?.profile?.picture}
                          alt={row.username}
                          style={{ width: 40, height: 40 }}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.username}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Alert severity="info" style={{ marginTop: 15 }}>
                No users unselected
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: "1px 24px" }}>
              <h2>Select profile</h2>
              <SelectProfile
                selected={selectedProfile}
                setSelected={setSelectedProfile}
              />
            </Paper>
            {!selectedProfile ? (
              <Alert severity="error" style={{ marginTop: 15 }}>
                No profile selected
              </Alert>
            ) : (
              <Paper
                style={{
                  padding: "32px",
                  marginTop: 15,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  src={selectedProfile?.profile?.picture}
                  alt={selectedProfile.username}
                  style={{ width: 180, height: 180 }}
                />
                <h2 style={{ margin: 0, marginTop: 30 }}>
                  {selectedProfile.username}
                </h2>
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
