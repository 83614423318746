/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { months } from "../../data";
import moment from "moment";
import styled from "styled-components";

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Row = styled.div`
  display: flex;

  &::last-child {
    margin-bottom: 0;
  }

  > div {
    margin-left: 15px;
  }
`;
export default function Filters({
  onStartChange,
  onEndChange,
  defaultStartDate,
  defaultEndDate,
}) {
  const startDate = moment(defaultStartDate, "DD.MM.YYYY")
    .subtract(1, "day")
    .startOf("day");
  const endDate = moment(defaultEndDate, "DD.MM.YYYY").endOf("day");

  const [fromYear, setFromYear] = useState({
    key: startDate.format("YYYY"),
    label: startDate.format("YYYY"),
  });
  const [fromMonth, setFromMonth] = useState({
    key: startDate.format("M"),
    label: startDate.format("M"),
  });
  const [fromDay, setFromDay] = useState({
    key: startDate.format("D"),
    label: startDate.format("D"),
  });

  const [toYear, setToYear] = useState({
    key: endDate.format("YYYY"),
    label: endDate.format("YYYY"),
  });
  const [toMonth, setToMonth] = useState({
    key: endDate.format("M"),
    label: endDate.format("M"),
  });
  const [toDay, setToDay] = useState({
    key: endDate.format("D"),
    label: endDate.format("D"),
  });

  const days = moment().daysInMonth();

  let daysArray = [];
  let yearsArray = [];

  for (var start = 1; start <= days; start++) {
    const day = {
      key: start,
      label: start.toString(),
      value: start,
    };
    daysArray.push(day);
  }

  var min = 2019,
    max = moment().year();

  for (var i = min; i <= max; i++) {
    const year = {
      key: i,
      label: i.toString(),
      value: i,
    };
    yearsArray.push(year);
  }

  return (
    <FiltersWrapper>
      <Row>
        <div style={{ width: 50 }}>
          <h4>From:</h4>
        </div>
        <Autocomplete
          disableClearable={true}
          options={yearsArray}
          value={fromYear}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          getOptionSelected={(option, value) => {
            return option.label === value.label;
          }}
          onChange={(event, newValue) => {
            setFromYear(newValue);
            onStartChange(
              moment().set({
                year: newValue.key,
                month: fromMonth.key,
                day: fromDay.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Year" variant="outlined" />
          )}
        />
        <Autocomplete
          disableClearable={true}
          options={months}
          value={fromMonth}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          onChange={(event, newValue) => {
            setFromMonth(newValue);
            onStartChange(
              moment().set({
                year: fromYear.key,
                month: newValue.key,
                day: fromDay.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Month" variant="outlined" />
          )}
        />
        <Autocomplete
          disableClearable={true}
          options={daysArray}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          value={fromDay}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          onChange={(event, newValue) => {
            setFromDay(newValue);
            onStartChange(
              moment().set({
                year: fromYear.key,
                month: fromYear.key,
                day: newValue.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Day" variant="outlined" />
          )}
        />
      </Row>

      <Row>
        <div style={{ width: 50 }}>
          <h4>To:</h4>
        </div>
        <Autocomplete
          disableClearable={true}
          options={yearsArray}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          value={toYear}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          onChange={(event, newValue) => {
            setToYear(newValue);
            onEndChange(
              moment().set({
                year: newValue.key,
                month: toMonth.key,
                day: toDay.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Day" variant="outlined" />
          )}
        />
        <Autocomplete
          disableClearable={true}
          options={months}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          value={toMonth}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          onChange={(event, newValue) => {
            setToMonth(newValue);
            onEndChange(
              moment().set({
                year: toYear.key,
                month: newValue.key,
                day: toDay.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Day" variant="outlined" />
          )}
        />
        <Autocomplete
          disableClearable={true}
          options={daysArray}
          getOptionLabel={(option) => option.label}
          style={{ width: 150 }}
          value={toDay}
          getOptionSelected={(option, value) => {
            return option.key === value.key;
          }}
          onChange={(event, newValue) => {
            setToDay(newValue);
            onEndChange(
              moment().set({
                year: toYear.key,
                month: toMonth.key,
                day: newValue.key,
              })
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Day" variant="outlined" />
          )}
        />
      </Row>
    </FiltersWrapper>
  );
}
