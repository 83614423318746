import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Header from "../Modal/Header";
import Actions from "../Modal/Actions";
import Content from "../Modal/Content";
import GlobalLoader from "../GlobalLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { addUser } from "../../redux/actions/usersActions";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    minWidth: 500,
    maxWidth: "500px",
  },
}));

function AddCoinPack(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const _handleSubmit = (values, formikApi) => {
    const {
      firstname,
      lastname,
      username,
      email,
      password,
      confirmPassword,
    } = values;
    const data = {
      firstname,
      lastname,
      username,
      email,
      password,
      confirmPassword,
      type: 5,
    };
    props.addUser(data, formikApi, setOpen);
  };

  return (
    <React.Fragment>
      <Button color='primary' variant='contained' onClick={handleOpen}>
        ADD NEW
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().required("Required"),
                lastname: Yup.string().required("Required"),
                username: Yup.string().required("Required"),
                email: Yup.string().required("Required"),
                password: Yup.string().required("Required"),
                confirmPassword: Yup.string().required("Required"),
              })}
              onSubmit={_handleSubmit}>
              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <GlobalLoader></GlobalLoader>}
                  <Header title='Add new admin' />
                  <Content>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type='text'
                          margin='normal'
                          fullWidth
                          id='firstname'
                          label='Firstname'
                          name='firstname'
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          type='text'
                          margin='normal'
                          fullWidth
                          id='lastname'
                          label='Lastname'
                          name='lastname'
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type='text'
                          margin='normal'
                          fullWidth
                          id='username'
                          label='Username'
                          name='username'
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type='email'
                          margin='normal'
                          fullWidth
                          id='email'
                          label='Email'
                          name='email'
                          variant='outlined'
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type='password'
                          margin='normal'
                          fullWidth
                          id='password'
                          label='Password'
                          name='password'
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type='password'
                          margin='normal'
                          fullWidth
                          id='confirmPassword'
                          label='Confirm Password'
                          name='confirmPassword'
                          variant='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Content>
                  <Actions>
                    <Button
                      variant='contained'
                      onClick={handleClose}
                      style={{ marginRight: 15 }}>
                      Close
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isSubmitting}
                      onClick={submitForm}>
                      Submit
                    </Button>
                  </Actions>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect((state) => state, { addUser })(AddCoinPack);
