import React from "react";
import { connect } from "react-redux";
import { getMessagesChatter } from "../../redux/actions/messengerActions";
import Table from "../../components/Table";
import Moment from "react-moment";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
function Messages(props) {
  const { messages, messagesData, messagesLoading } = props.messenger;
  const columns = [
    {
      id: "image",
      Header: "Bild",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.member && props.original.member.profile && (
            <Link to={`/messenger/${props.original.channelId}`}>
              <Avatar
                src={props.original.member.profile.picture}
                alt={props.original.member.username}
              />
            </Link>
          )}
        </React.Fragment>
      ),
    },

    {
      id: "client",
      Header: "Kunden",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Link to={`/messenger/${props.original.channelId}`}>
            {props.original.member.username}
          </Link>
        </strong>
      ),
    },
    {
      id: "body",
      Header: "Message",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Tooltip title={props.original.body}>
          <span style={{ display: "block", maxWidth: "100%" }}>
            {props.original.body}
          </span>
        </Tooltip>
      ),
    },
    {
      id: "type",
      Header: "Type",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.type === 1 ? "In" : "Out"}</strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Sent Date",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "profile",
      Header: "Profile",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.user.username}</strong>,
    },
    {
      id: "profileImage",
      Header: "Profile Image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.user && props.original.user.profile && (
            <Avatar
              src={props.original.user.profile.picture}
              alt={props.original.user.username}
            />
          )}
        </React.Fragment>
      ),
    },
  ];

  return (
    <Table
      data={messages}
      columns={columns}
      loading={messagesLoading}
      page={!messagesData.pagination ? 0 : messagesData.pagination.current - 1}
      pages={!messagesData.pagination ? 1 : messagesData.pagination.total}
      onFetchData={(state) => {
        var queryString = state.filtered
          .map((key) => key.id + "=" + key.value)
          .join("&");
        let query = `?page=${state.page + 1}&chatterId=${
          props.chatter
        }&${queryString}`;
        props.getMessagesChatter(query);
      }}
      noDataText="No messages"
    />
  );
}

const mapStateToProps = (state) => ({
  messenger: state.messenger,
});

export default connect(mapStateToProps, { getMessagesChatter })(Messages);
