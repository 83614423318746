import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import usersReducer from "./usersReducer";
import globalReducer from "./globalReducer";
import giftReducer from "./giftReducer";
import coinReducer from "./coinReducer";
import couponReducer from "./couponReducer";
import orderReducer from "./orderReducer";
import messengerReducer from "./messengerReducer";
import socketReducer from "./socketReducer";
import activityReducer from "./activityReducer";

import { DESTROY_SESSION } from "../types";

// Combine all reducers.
const appReducer = combineReducers({
  error: errorReducer,
  auth: authReducer,
  users: usersReducer,
  global: globalReducer,
  gift: giftReducer,
  coin: coinReducer,
  coupon: couponReducer,
  order: orderReducer,
  messenger: messengerReducer,
  socket: socketReducer,
  activity: activityReducer
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
