import React, { Component } from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
`;

export default class LayoutCanvas extends Component {
  render() {
    return <Content>{this.props.children}</Content>;
  }
}
