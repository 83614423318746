import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import { API } from "../../config";
import Loader from "../LoaderPage";
import CardWithHeader from "../Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { api } from "../../lib/api";

function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function Admin(props) {
  const { data, error } = useSWR(`${API}/info/admin`, api);
  if (error) return <div></div>;
  if (!data) return <Loader />;
  const info = data.data;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card title="Total Clients" number={info.users} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="New Clients" number={info.newUsers} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="In" number={info.ins} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Out" number={info.outs} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Active user" number={info.activeUsers} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Sent Gifts" number={info.sentGifts} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Orders" number={info.orders} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Active Dialogues" number={info.activeDialogues} />
        </Grid>
      </Grid>

      <CardWithHeader
        title="Best Clients"
        style={{ marginTop: 15, height: "100%" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="right">Payments Done</TableCell>
              <TableCell align="right">Current coins</TableCell>
              <TableCell align="right">Payments Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {info.bestClients.map((client) => (
              <TableRow
                key={client._id}
                style={{
                  background: client.deleted ? "#ffafaf" : "transparent",
                }}
              >
                <TableCell component="th" scope="row">
                  <Link to={`/client/${client._id}`}>{client.username}</Link>
                </TableCell>
                <TableCell align="right">{client.paymentsDone}</TableCell>
                <TableCell align="right">{client.coins}</TableCell>
                <TableCell align="right">
                  {client.paymentsAmount.toFixed() + "€"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardWithHeader>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Admin);
