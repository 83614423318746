import React from "react";
import LayoutDefault from "../../Layouts/Default";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const Default = ({ component: Component, setTheme, theme, auth, ...rest }) => {
  const { isLoading, isAuthenticated, user } = auth;
  if (isLoading === false) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && user ? (
            <LayoutDefault setTheme={setTheme} theme={theme}>
              <Component {...props} />
            </LayoutDefault>
          ) : (
            <Redirect to='/login' />
          )
        }
      />
    );
  } else {
    return <React.Fragment />;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Default);
