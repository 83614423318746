import {
  GET_COUPONS,
  GET_COUPON,
  ADD_COUPON,
  COUPONS_LOADING,
  DELETE_COUPON,
} from "../types";

const initialState = {
  coupons: [],
  coupon: null,
  data: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_COUPON:
      return {
        ...state,
        coupon: action.payload.data,
      };
    case ADD_COUPON:
      return {
        ...state,
        coupons: [action.payload.data, ...state.coupons],
      };
    case DELETE_COUPON:
      return {
        ...state,
        coupons: state.coupons.filter(
          (coupon) => coupon._id !== action.payload
        ),
      };
    case COUPONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
