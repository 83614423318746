import {
  GET_GIFTS,
  GET_GIFT,
  ADD_GIFT,
  GIFTS_LOADING,
  DELETE_GIFT,
  GET_GIFTS_SENT,
  GET_GIFTS_RECEIVED,
  UPDATE_GIFT,
} from "../types";

const initialState = {
  gifts: [],
  data: [],
  gift: null,
  sentGifts: [],
  sentGiftsData: [],

  receivedGifts: [],
  receivedGiftsData: [],

  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GIFTS:
      return {
        ...state,
        gifts: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_GIFTS_SENT:
      return {
        ...state,
        sentGifts: action.payload.data,
        sentGiftsData: action.payload,
        loading: false,
      };
    case GET_GIFTS_RECEIVED:
      return {
        ...state,
        receivedGifts: action.payload.data,
        receivedGiftsData: action.payload,
        loading: false,
      };
    case GET_GIFT:
      return {
        ...state,
        gifts: action.payload.data,
      };
    case ADD_GIFT:
      return {
        ...state,
        gifts: [action.payload.data, ...state.gifts],
      };
    case UPDATE_GIFT:
      var foundIndex = state.gifts.findIndex(
        (x) => x._id === action.payload._id
      );
      state.gifts[foundIndex] = action.payload;
      return {
        ...state,
      };
    case DELETE_GIFT:
      return {
        ...state,
        gifts: state.gifts.filter((gift) => gift._id !== action.payload),
      };
    case GIFTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
