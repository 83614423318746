import React, { Component } from "react";
import Card from "../Card";
import FileUpload from "../FileUpload";
import Button from "@material-ui/core/Button";
import { API } from "../../config";
import cogoToast from "cogo-toast";
import { api } from "../../lib/api";
class MultipleUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      imageArray: [],
      showMessage: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectorFiles) {
    if (selectorFiles) {
      const files = Array.from(selectorFiles);

      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              const image = {
                file: file,
                preview: ev.target.result,
              };
              resolve(image);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          this.setState({ imageArray: images });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleUploadImages = () => {
    const formData = new FormData();
    this.state.imageArray.forEach((file) => {
      formData.append("file", file.file);
    });
    api
      .post(`${API}/users/profile-images/${this.props.userId}`, formData)
      .then((res) => {
        this.setState({ imageArray: [] });

        cogoToast.success(`${res.data.data}`, {
          position: "top-right",
        });
      });
  };

  render() {
    return (
      <Card
        title="Upload images"
        style={{ marginTop: 15 }}
        contentStyles={{ padding: 15 }}
        button={
          <React.Fragment>
            {this.state.imageArray.length > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={this.handleUploadImages}
              >
                Upload selected images
              </Button>
            )}
          </React.Fragment>
        }
      >
        <FileUpload
          handleChange={this.handleChange}
          data={this.state.imageArray}
        />
      </Card>
    );
  }
}

export default MultipleUpload;
