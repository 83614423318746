import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateUser, getUser } from "../../redux/actions/usersActions";
import * as Yup from "yup";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class EditUser extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    let userId = this.props.match.params.id;
    this.props.getUser(userId);
  }

  _handleSubmit = (values) => {
    const {
      firstname,
      lastname,
      gender,
      birthday,
      userFunction,
      phone,
    } = values;

    const data = {
      profile: {
        firstname,
        lastname,
        name: firstname + " " + lastname,
        gender,
        birthday,
        function: userFunction,
        phone,
      },
    };

    let userID = this.props.match.params.id;
    this.props.updateUser(this.props.history, userID, data);
  };

  render() {
    const { user } = this.props.users;

    return (
      <React.Fragment>
        {user && (
          <Formik
            validationSchema={Yup.object().shape({
              firstname: Yup.string().required("Required"),
              lastname: Yup.string().required("Required"),
              email: Yup.string().required("Required"),
            })}
            onSubmit={this._handleSubmit}
            initialValues={{
              firstname: !user.profile ? "" : user.profile.firstname,
              lastname: !user.profile ? "" : user.profile.lastname,
              gender: !user.profile ? "" : user.profile.gender,
              birthday: !user.profile ? "" : user.profile.birthday,
              phone: !user.profile ? "" : user.profile.phone,
              email: user.email,
              role: user.role,
            }}>
            {({ submitForm, isSubmitting, dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Paper style={{ padding: 15 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            type='text'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='firstname'
                            label='First Name'
                            name='firstname'
                            style={{ margin: 0 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            type='text'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='lastname'
                            label='Last Name'
                            name='lastname'
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            type='text'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='gender'
                            label='Gender'
                            name='gender'
                            style={{ margin: 0 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            type='text'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='birthday'
                            label='Birthday'
                            name='birthday'
                            style={{ margin: 0 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            type='text'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='phone'
                            label='Phone'
                            name='phone'
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Field
                            component={TextField}
                            type='email'
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='email'
                            label='Email'
                            name='email'
                            disabled
                            style={{ margin: 0 }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Paper style={{ padding: 15, marginBottom: 15 }}>
                      <Button
                        color='primary'
                        variant='contained'
                        fullWidth
                        type='submit'
                        disabled={!dirty}
                        style={{ padding: "16px 32px" }}>
                        Save Changes
                      </Button>
                    </Paper>

                    <Paper>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell component='th'>Register Date</TableCell>
                            <TableCell align='right'>
                              <Moment>{user.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component='th'>Last Updated</TableCell>
                            <TableCell align='right'>
                              <Moment>{user.updatedAt}</Moment>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  users: state.users,
});

export default connect(mapStateToProps, {
  updateUser,
  getUser,
})(EditUser);
