import React, { Component } from "react";
import { connect } from "react-redux";
import { updateProfile, getUser } from "../../redux/actions/usersActions";
import * as Yup from "yup";
import Moment from "react-moment";
import { Formik, Form, Field } from "formik";
import { TextField } from "material-ui-formik-components/TextField";
import { Select } from "material-ui-formik-components/Select";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Card from "../../components/Card";
import styled from "styled-components";
import Images from "../../components/User/Images";
import UploadAvatar from "../../components/User/UploadAvatar";
import MultipleUpload from "../../components/Profiles/MultipleUpload";
import Loader from "../../components/LoaderPage";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const Label = styled.label`
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  display: block;
`;

class EditUser extends Component {
  async componentDidMount() {
    let userId = this.props.match.params.id;
    this.props.getUser(userId);
  }

  _handleSubmit = (values) => {
    const {
      size,
      hair,
      eye,
      figure,
      smoker,
      searching,
      status,
      children,
      job,
      languages,
      description,
      birthday,
      country,
      zip,
      city,
      gender,
      interested,
    } = values;

    const data = {
      size,
      hair,
      eye,
      figure,
      smoker,
      searching,
      status,
      children,
      job,
      languages,
      description,
      birthday,
      country,
      zip,
      city,
      gender,
      interested,
    };

    let userID = this.props.match.params.id;
    this.props.updateProfile(this.props.history, userID, data);
  };

  render() {
    const { user } = this.props.users;

    if (!user) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        {user && (
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Required"),
            })}
            onSubmit={this._handleSubmit}
            initialValues={{
              username: user.username,
              email: user.email,
              size: user.size,
              hair: user.hair,
              eye: user.eye,
              figure: user.figure,
              smoker: user.smoker,
              searching: user.searching,
              status: user.status,
              children: user.children,
              job: user.job,
              languages: user.languages,
              description: user.description,
              birthday: user.birthday,
              country: user.country,
              zip: user.zip,
              city: user.city,
              gender: user.gender,
              interested: user.interested,
            }}
          >
            {({ dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Card title="Account" contentStyles={{ padding: 15 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Label>Username</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Username"
                            name="username"
                            disabled
                            style={{ margin: 0 }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Label>Email</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Email"
                            name="email"
                            disabled
                            style={{ margin: 0 }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <Card
                      title="About"
                      contentStyles={{ padding: 15 }}
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <Label>Größe (cm)</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Größe (cm)"
                            name="size"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Haarfarbe</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Haarfarbe"
                            name="hair"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Augenfarbe</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Augenfarbe"
                            name="eye"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Figur</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Figur"
                            name="figure"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Raucher</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Raucher"
                            name="smoker"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Suche</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Suche"
                            name="searching"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Beziehungsstatus</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Beziehungsstatus"
                            name="status"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Kinder</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Kinder"
                            name="children"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Beruf</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Beruf"
                            name="job"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Sprache</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Sprache"
                            name="languages"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Flirttext</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Flirttext"
                            name="about"
                            style={{ margin: 0 }}
                          />
                        </Grid>
                      </Grid>
                    </Card>

                    <Card
                      title="Info"
                      contentStyles={{ padding: 15 }}
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <Label>Geburtsdatum</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={KeyboardDatePicker}
                            name="birthday"
                            label="Geburtsdatum"
                            inputVariant="outlined"
                            fullWidth
                            placeholder="10/10/1989"
                            format="MM/dd/yyyy"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Land</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Land"
                            name="country"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>PLZ</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="PLZ"
                            name="zip"
                            style={{ margin: 0 }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Wohnort</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            type="text"
                            fullWidth
                            label="Wohnort"
                            name="city"
                            style={{ margin: 0 }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Label>Ich bin</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={Select}
                            name="gender"
                            variant="outlined"
                            label="Ich bin"
                            style={{ margin: 0 }}
                            options={[
                              { value: "Mann", label: "Mann" },
                              { value: "Frau", label: "Frau" },
                            ]}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Label>Ich suche</Label>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={Select}
                            name="interested"
                            variant="outlined"
                            label="Ich suche"
                            style={{ margin: 0 }}
                            options={[
                              { value: "Mann", label: "Mann" },
                              { value: "Frau", label: "Frau" },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <MultipleUpload userId={user._id} />
                    <Images userId={user._id} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Paper style={{ padding: 15, marginBottom: 15 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={!dirty}
                        style={{ padding: "16px 32px" }}
                      >
                        Save Changes
                      </Button>
                    </Paper>

                    <Paper style={{ overflow: "hidden" }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th">Register Date</TableCell>
                            <TableCell align="right">
                              <Moment>{user.createdAt}</Moment>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th">Last Updated</TableCell>
                            <TableCell align="right">
                              <Moment>{user.updatedAt}</Moment>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>

                    <Card
                      title="Profile picture"
                      contentStyles={{ padding: 15 }}
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <UploadAvatar
                        userId={user._id}
                        currentPicture={user.profile.picture}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  users: state.users,
});

export default connect(mapStateToProps, {
  updateProfile,
  getUser,
})(EditUser);
