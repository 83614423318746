import { returnErrors } from "./errorActions";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  DESTROY_SESSION,
  GET_OUTS,
  ADD_OUTS,
  LOGING_OUT_CHATTER,
  CHATTER_DIALOUGES,
} from "../types";
import { API } from "../../config";
import { api } from "../../lib/api";

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });
  api
    .get(`${API}/auth/account`)
    .then((res) => {
      if (res.data.data.type === 1) {
        window.location.href = "https://www.flirtdart.com";
      } else {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(returnErrors("No token, authorizaton denied", 400));
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// Login User
export const login =
  ({ username, password }, formikApi) =>
  (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Request body
    const body = JSON.stringify({ username, password });

    api
      .post(`${API}/auth/login`, body, config)
      .then((res) => {
        if (res.data.data.type === 1) {
          dispatch({ type: DESTROY_SESSION });
          dispatch({ type: LOGOUT_SUCCESS });
          window.location.href = "https://www.flirtdart.com";
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: USER_LOADED,
            payload: res.data,
          });
        }

        formikApi.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(
          returnErrors(
            error.response.data.error,
            error.response.status,
            "LOGIN_FAIL"
          )
        );
        formikApi.setSubmitting(false);
        dispatch({
          type: LOGIN_FAIL,
        });
      });
  };

export const getOuts = (id) => {
  return (dispatch) => {
    api.get(`${API}/users/outs/${id}`).then((response) => {
      dispatch({
        type: GET_OUTS,
        payload: response.data,
      });
    });
  };
};
export const addOuts = (amount) => {
  return (dispatch) => {
    dispatch({
      type: ADD_OUTS,
      payload: amount,
    });
  };
};

// Check token & load user
export const logout = () => (dispatch, getState) => {
  dispatch({ type: DESTROY_SESSION });
  dispatch({ type: LOGOUT_SUCCESS });
};

export const logOutChatter = () => (dispatch, getState) => {
  dispatch({ type: LOGING_OUT_CHATTER });
  api.get(`${API}/auth/chatter-dialogues`).then((res) => {
    dispatch({ type: CHATTER_DIALOUGES, payload: res.data.data });
    setInterval(() => {
      api.post(`${API}/auth/logout-chatter`).then((res) => {
        dispatch({ type: DESTROY_SESSION });
        dispatch({ type: LOGOUT_SUCCESS });
      });
    }, 2000);
  });
};
