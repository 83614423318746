import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";

import { API } from "../../config";
import Card from "../../components/Card";
import { FaDirections } from "react-icons/fa";
import Loader from "../../components/LoaderPage";
import SentGifts from "../../components/User/SentGifts";
import ReceivedGifts from "../../components/User/ReceivedGifts";
import Payments from "../../components/User/Payments";
import Channels from "./Channels";
import Images from "../../components/User/Images";
import VerifyEmail from "../../components/User/VerifyEmail";
import UpdateCoins from "../../components/User/UpdateCoins";
import Authorize from "../../utils/Authorize";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Activity from "./Activity";
import Coinsactivity from "./CoinsActivity";
import { api } from "../../lib/api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingTop: 10 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Client(props) {
  const [data, setData] = useState(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    api.get(`${API}/users/id/${props.match.params.id}/`).then((res) => {
      setData(res.data);
    });
  }, [props.match.params.id]);
  if (!data) return <Loader />;
  const userData = data.data;
  const sentMessage = data.sentMessage;
  const receivedMessages = data.receivedMessages;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <AppBar
            position="static"
            style={{
              borderRadius: 5,
              overflow: "hidden",
              background: "#fff",
              color: "#717CBD",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="About" {...a11yProps(0)} />
              <Tab label="Location" {...a11yProps(1)} />
              <Tab label="Payments" {...a11yProps(2)} />
              <Tab label="Channels" {...a11yProps(3)} />
              <Tab label="Sent Gifts" {...a11yProps(4)} />
              <Tab label="Received Gifts" {...a11yProps(5)} />
              <Tab label="Images" {...a11yProps(6)} />
              <Tab label="Activity" {...a11yProps(7)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <Table size="small" component={Paper}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Name
                  </TableCell>
                  <TableCell align="right">
                    {userData.profile.firstname ? userData.profile.name : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Username
                  </TableCell>
                  <TableCell align="right">{userData.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="right">{userData.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Gender
                  </TableCell>
                  <TableCell align="right">
                    {userData.profile.gender ? userData.profile.gender : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Birthday
                  </TableCell>
                  <TableCell align="right">
                    {userData.profile.birthday ? (
                      <Moment>{userData.profile.birthday}</Moment>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IP
                  </TableCell>
                  <TableCell align="right">
                    {userData.ip ? userData.ip : "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Sucht
                  </TableCell>
                  <TableCell align="right">
                    {userData.interested ? userData.interested : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Alter
                  </TableCell>
                  <TableCell align="right">
                    {userData.birthday ? (
                      <Moment>{userData.birthday}</Moment>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Größe (cm)
                  </TableCell>
                  <TableCell align="right">
                    {userData.size ? userData.size : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Haarfarbe
                  </TableCell>
                  <TableCell align="right">
                    {userData.hair ? userData.hair : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Augenfarbe
                  </TableCell>
                  <TableCell align="right">
                    {userData.eye ? userData.eye : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Figur
                  </TableCell>
                  <TableCell align="right">
                    {userData.figure ? userData.figure : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Raucher
                  </TableCell>
                  <TableCell align="right">
                    {userData.smoker ? userData.smoker : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Suche
                  </TableCell>
                  <TableCell align="right">
                    {userData.searching ? userData.searching : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Beziehungsstatus
                  </TableCell>
                  <TableCell align="right">
                    {userData.status ? userData.status : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Kinder
                  </TableCell>
                  <TableCell align="right">
                    {userData.children ? userData.children : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Beruf
                  </TableCell>
                  <TableCell align="right">
                    {userData.job ? userData.job : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sprache
                  </TableCell>
                  <TableCell align="right">
                    {userData.language ? userData.language : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={6}>
                <Card
                  title="Tracked Location"
                  button={
                    <Button
                      component="a"
                      href={`http://maps.google.com/?q=${
                        userData.generatedLocation &&
                        userData.generatedLocation.coordinates
                      }`}
                      target="_blank"
                      startIcon={<FaDirections></FaDirections>}
                      variant="contained"
                      color="primary"
                      style={{
                        color: "#fff",
                        backgroundColor: "#4285f4",
                      }}
                    >
                      Map
                    </Button>
                  }
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Country
                        </TableCell>
                        <TableCell align="right">
                          {userData.generatedLocation &&
                            userData.generatedLocation.country}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          City
                        </TableCell>
                        <TableCell align="right">
                          {userData.generatedLocation &&
                            userData.generatedLocation.city}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Zip
                        </TableCell>
                        <TableCell align="right">
                          {userData.generatedLocation &&
                            userData.generatedLocation.zip}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card title="Real Location">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Country
                        </TableCell>
                        <TableCell align="right">{userData.country}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          City
                        </TableCell>
                        <TableCell align="right">{userData.city}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Zip
                        </TableCell>
                        <TableCell align="right">{userData.zip}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Authorize roles={[6]}>
              <Payments userId={userData._id} />
            </Authorize>
          </TabPanel>

          {userData && (
            <React.Fragment>
              <TabPanel value={value} index={3}>
                <Channels userId={userData._id} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <SentGifts userId={userData._id} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ReceivedGifts userId={userData._id} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Images userId={userData._id} />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <Activity userId={userData._id} />
                <Coinsactivity userId={userData._id} />
              </TabPanel>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          {userData.profile.picture && (
            <Paper style={{ padding: 15, marginBottom: 15 }}>
              <img
                src={userData.profile.picture}
                alt="user"
                style={{ width: "100%" }}
              />
            </Paper>
          )}

          <Paper style={{ overflow: "hidden" }}>
            <Table>
              <TableBody>
                <Authorize roles={[6, 5]}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Coins
                    </TableCell>
                    <TableCell align="right">
                      {userData.coins}
                      <UpdateCoins
                        currentCoins={userData.coins}
                        userId={userData._id}
                      />
                    </TableCell>
                  </TableRow>
                </Authorize>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Register Date
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{userData.createdAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Online
                  </TableCell>
                  <TableCell align="right">
                    <Moment>{userData.updatedAt}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Online
                  </TableCell>
                  <TableCell align="right">
                    {userData.online ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sent Messages
                  </TableCell>
                  <TableCell align="right">{sentMessage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Received Messages
                  </TableCell>
                  <TableCell align="right">{receivedMessages}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Referer name
                  </TableCell>
                  <TableCell align="right">
                    {userData.referer
                      ? userData.referer.username
                      : "No referer"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Referer ID
                  </TableCell>
                  <TableCell align="right">
                    {userData.referer ? userData.referer.refId : "No referer"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email Verified
                  </TableCell>
                  <TableCell align="right">
                    {userData.emailVerified ? (
                      "Yes"
                    ) : (
                      <Authorize roles={[6, 5]}>
                        <VerifyEmail userId={userData._id} />
                      </Authorize>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Deleted
                  </TableCell>
                  <TableCell align="right">
                    {userData.deleted ? "Yes" : "No"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Deleted for advertisers
                  </TableCell>
                  <TableCell align="right">
                    {userData.deletedAdmin ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
