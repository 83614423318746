import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../redux/actions/usersActions";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import UsersTable from "./Table";
import Button from "@material-ui/core/Button";
import Filters from "../../components/Clients/Filters";
import moment from "moment";
const queryString = require("query-string");

function Clients(props) {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    name: StringParam,
    email: StringParam,
    ip: StringParam,
    type: withDefault(NumberParam, 1),
    emailVerified: withDefault(StringParam),
    deletedAdvertisers: withDefault(StringParam),
    payments: withDefault(StringParam),
    from: withDefault(
      StringParam,
      moment().subtract(1, "day").format("DD.MM.YYYY")
    ),
    to: withDefault(StringParam, moment().format("DD.MM.YYYY")),
  });

  const clearFilters = () => {
    setQuery({
      page: 1,
      name: null,
      email: null,
      ip: null,
      type: 1,
      emailVerified: null,
      deletedAdvertisers: null,
      payments: null,
      from: moment().subtract(1, "day").format("DD.MM.YYYY"),
      to: moment().format("DD.MM.YYYY"),
    });
  };

  const handleFetch = () => {
    props.getUsers(
      `?${queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      })}`
    );
  };

  useEffect(() => {
    handleFetch();
  }, [query]);

  const { users, data, loading } = props.users;

  const onEndChange = (val) => {
    setQuery({
      to: moment(val).format("DD.MM.YYYY"),
    });
    console.log(val);
  };
  const onStartChange = (val) => {
    setQuery({
      from: moment(val).format("DD.MM.YYYY"),
    });
  };
  return (
    <div>
      <div style={{ display: "flex", marginBottom: 30, alignItems: "center" }}>
        <Button color="primary" variant="contained" onClick={handleFetch}>
          Refresh
        </Button>
        <Button
          color="primary"
          style={{ marginLeft: 15 }}
          variant="contained"
          onClick={clearFilters}
        >
          Clear filters
        </Button>
        <Filters
          defaultStartDate={query.from}
          defaultEndDate={query.to}
          onStartChange={onStartChange}
          onEndChange={onEndChange}
        />
      </div>
      <UsersTable
        rows={users}
        data={data}
        query={query}
        setQuery={setQuery}
        loading={loading}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUsers })(Clients);
