/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Moment from "react-moment";
import {
  getMessages,
  getMoreMessages,
} from "../../../redux/actions/messengerActions";
import $ from "jquery";
import Loader from "../../Loader";
import BounceLoader from "react-spinners/BounceLoader";
import Button from "@material-ui/core/Button";

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 237px);
  overflow-y: scroll;
`;

const NewChannel = styled.div`
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  svg {
    max-width: 300px;
    max-height: 300px;
  }
  h1 {
    font-size: 18px;
  }
`;
const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 15px;
  align-items: flex-start;

  .pill {
    background: ${(props) => props.theme.palette.divider};
    padding: 8px 13px;
    border-radius: 5px;
    max-width: 65%;
    word-break: break-all;
  }

  .image--pill {
    background: rgba(0, 0, 0, 0.05);
    padding: 8px 13px;
    border-radius: 5px;
    max-width: 400px;
    word-break: break-all;
  }

  &.me {
    justify-content: flex-end;
    .pill {
      background: #2ecc71;
      color: #fff;
    }
  }
`;

function Content({
  member,
  profile,
  getMessages,
  messenger,
  isChatter,
  getMoreMessages,
}) {
  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    getMessages(member._id, profile._id);
  }, []);

  const { messages, messagesLoading, messagesData, count } = messenger;

  const handleGetMore = () => {
    getMoreMessages(
      member._id,
      profile._id,
      messagesData.pagination.current + 1,
      setLoadingMore
    );
  };

  useEffect(() => {
    $("#messages").animate(
      { scrollTop: $("#messages").prop("scrollHeight") },
      200
    );
  }, [count]);

  const sorted = messages.sort(function (a, b) {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  return (
    <Loader loading={messagesLoading}>
      <MessagesWrapper id="messages" isChatter={isChatter}>
        {messagesData?.pagination?.current < messagesData?.pagination?.total &&
          !loadingMore && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 30,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetMore}
              >
                Load more
              </Button>
            </div>
          )}
        {loadingMore && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 30,
            }}
          >
            <BounceLoader color="#000" />
          </div>
        )}
        {sorted.length > 0 ? (
          <React.Fragment>
            {sorted.map((message) => (
              <Message
                className={`${message.type === 2 ? "me" : "member"}`}
                key={message._id}
              >
                <React.Fragment>
                  {message.type == 1 && (
                    <div className="pill">
                      {message.body}

                      <div>
                        Sent date:
                        <strong>
                          {" "}
                          <Moment format="DD-MM-YYYY HH:mm:ss">
                            {message.createdAt}
                          </Moment>
                        </strong>
                      </div>
                    </div>
                  )}
                  {message.type == 2 && (
                    <div className="pill">
                      <div>
                        Sent by:
                        <strong>
                          {message.chatterId
                            ? message.chatterId.username
                            : "User deleted"}
                        </strong>
                      </div>
                      {message.body}
                      <div>
                        Sent date:
                        <strong>
                          {" "}
                          <Moment format="DD-MM-YYYY HH:mm:ss">
                            {message.createdAt}
                          </Moment>
                        </strong>
                      </div>
                    </div>
                  )}
                  {message.type == 3 && (
                    <div className="image-pill" style={{ height: 250 }}>
                      <img
                        src={message.body}
                        alt=""
                        style={{ maxWidth: 250, padding: 15 }}
                      />
                      <div>
                        Sent date:
                        <strong>
                          {" "}
                          <Moment format="DD-MM-YYYY HH:mm:ss">
                            {message.createdAt}
                          </Moment>
                        </strong>
                      </div>
                    </div>
                  )}

                  {message.type == 4 && (
                    <div className="pill">
                      {message.body}
                      <div>
                        Sent date:
                        <strong>
                          {" "}
                          <Moment format="DD-MM-YYYY HH:mm:ss">
                            {message.createdAt}
                          </Moment>
                        </strong>
                      </div>
                    </div>
                  )}
                  {message.type == 4 && (
                    <div className="pill">
                      User send you a friend request
                      <div>
                        Sent date:
                        <strong>
                          {" "}
                          <Moment format="DD-MM-YYYY HH:mm:ss">
                            {message.createdAt}
                          </Moment>
                        </strong>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              </Message>
            ))}
          </React.Fragment>
        ) : (
          <NewChannel>
            <h1>Start a new chat with {member.username} by saying hi!</h1>
          </NewChannel>
        )}
      </MessagesWrapper>
    </Loader>
  );
}

export default connect((state) => state, { getMessages, getMoreMessages })(
  Content
);
