import React, { useState } from "react";
import { connect } from "react-redux";
import { API } from "../config";
import Grid from "@material-ui/core/Grid";
import Chat from "../components/Messenger/Chat";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";
import Loader from "../components/LoaderPage";
import { Scrollbars } from "react-custom-scrollbars";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import Header from "../components/Messenger/Header";
import { api } from "../lib/api";

function Member({ data, channel }) {
  const [name, setName] = useState(channel.memberInfo.name);
  const [city, setCity] = useState(channel.memberInfo.city);
  const [job, setJob] = useState(channel.memberInfo.job);
  const [workingHours, setWorkingHours] = useState(
    channel.memberInfo.workingHours
  );
  const [status, setStatus] = useState(channel.memberInfo.status);
  const [progress, setProgress] = useState(channel.memberInfo.progress);
  const [birthday, setBirthday] = useState(channel.memberInfo.birthday);
  const [notes, setNotes] = useState(channel.memberInfo.notes);
  const handleUpdate = () => {
    const data = {
      memberInfo: {
        name,
        city,
        job,
        workingHours,
        status,
        progress,
        birthday,
        notes,
      },
    };
    api.put(`${API}/channels/${channel._id}`, data).then((res) => {
      cogoToast.success("Info updated", {
        position: "top-right",
      });
    });
  };
  return (
    <div>
      <Avatar
        src={data.profile.picture}
        alt={data.username}
        style={{ height: 140, width: 140, margin: "40px auto" }}
      />
      <h1 style={{ textAlign: "center" }}>{data.username}</h1>
      <div style={{ padding: 15 }}>
        {data.bio ? (
          <React.Fragment>
            <h3>Flirttext</h3>
            <p>{data.description}</p>
          </React.Fragment>
        ) : (
          <Alert severity="info">
            <span>
              Leider hat {data.username} noch nichts über sich geschrieben.
              Schreibe {data.username} doch eine Nachricht, um herauszufinden
              warum. Sicherlich freut sich {data.username} über deine Nachricht.
            </span>
          </Alert>
        )}
      </div>
      <div style={{ padding: 15 }}>
        <TextField
          label="Notes"
          variant="outlined"
          multiline
          rows={5}
          fullWidth
          size="small"
          value={notes}
          style={{ marginBottom: 10 }}
          onChange={(e) => setNotes(e.target.value)}
          onBlur={handleUpdate}
        />

        <TextField
          label="Name"
          name="name"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Stadt"
          name="city"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Beruf"
          name="job"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={job}
          onChange={(e) => setJob(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Arbeitszeit"
          name="workingHours"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={workingHours}
          onChange={(e) => setWorkingHours(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Status"
          name="status"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Fortschritt"
          name="progress"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={progress}
          onChange={(e) => setProgress(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Geburtstag"
          name="birthday"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          onBlur={handleUpdate}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <span>Last updated</span>{" "}
          <strong>
            <Moment format="YYYY/MM/DD HH:mm">{data.updatedAt}</Moment>
          </strong>
        </div>
      </div>
      <Divider />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell align="right">{data.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Gender
            </TableCell>
            <TableCell align="right">
              {data.profile.gender ? data.profile.gender : "-"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Sucht
            </TableCell>
            <TableCell align="right">
              {data.interested ? data.interested : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Alter
            </TableCell>
            <TableCell align="right">
              {data.birthday ? data.birthday : "-"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Größe (cm)
            </TableCell>
            <TableCell align="right">{data.size ? data.size : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Haarfarbe
            </TableCell>
            <TableCell align="right">{data.hair ? data.hair : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Augenfarbe
            </TableCell>
            <TableCell align="right">{data.eye ? data.eye : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Figur
            </TableCell>
            <TableCell align="right">
              {data.figure ? data.figure : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Raucher
            </TableCell>
            <TableCell align="right">
              {data.smoker ? data.smoker : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Suche
            </TableCell>
            <TableCell align="right">
              {data.searching ? data.searching : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Beziehungsstatus
            </TableCell>
            <TableCell align="right">
              {data.status ? data.status : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Kinder
            </TableCell>
            <TableCell align="right">
              {data.children ? data.children : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Beruf
            </TableCell>
            <TableCell align="right">{data.job ? data.job : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Sprache
            </TableCell>
            <TableCell align="right">
              {data.language ? data.language : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

function Profile({ data, channel }) {
  const [name, setName] = useState(channel.profileInfo.name);
  const [city, setCity] = useState(channel.profileInfo.city);
  const [job, setJob] = useState(channel.profileInfo.job);
  const [workingHours, setWorkingHours] = useState(
    channel.profileInfo.workingHours
  );
  const [status, setStatus] = useState(channel.profileInfo.status);
  const [progress, setProgress] = useState(channel.profileInfo.progress);
  const [birthday, setBirthday] = useState(channel.profileInfo.birthday);
  const [notes, setNotes] = useState(channel.profileInfo.notes);
  const handleUpdate = () => {
    const data = {
      profileInfo: {
        name,
        city,
        job,
        workingHours,
        status,
        progress,
        birthday,
        notes,
      },
    };
    api.put(`${API}/channels/${channel._id}`, data).then((res) => {
      cogoToast.success("Info updated", {
        position: "top-right",
      });
    });
  };

  return (
    <div>
      <Link to={`/profile/${data._id}`}>
        <Avatar
          src={data.profile.picture}
          alt={data.username}
          style={{ height: 140, width: 140, margin: "40px auto" }}
        />
        <h1 style={{ textAlign: "center" }}>{data.username}</h1>
      </Link>

      <div style={{ padding: 15 }}>
        {data.bio ? (
          <React.Fragment>
            <h3>Flirttext</h3>
            <p>{data.description}</p>
          </React.Fragment>
        ) : (
          <Alert severity="info">
            <span>
              Leider hat {data.username} noch nichts über sich geschrieben.
              Schreibe {data.username} doch eine Nachricht, um herauszufinden
              warum. Sicherlich freut sich {data.username} über deine Nachricht.
            </span>
          </Alert>
        )}
      </div>
      <div style={{ padding: 15 }}>
        <TextField
          label="Notes"
          variant="outlined"
          multiline
          rows={5}
          fullWidth
          size="small"
          value={notes}
          style={{ marginBottom: 10 }}
          onChange={(e) => setNotes(e.target.value)}
          onBlur={handleUpdate}
        />

        <TextField
          label="Name"
          name="name"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Stadt"
          name="city"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Beruf"
          name="job"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={job}
          onChange={(e) => setJob(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Arbeitszeit"
          name="workingHours"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={workingHours}
          onChange={(e) => setWorkingHours(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Status"
          name="status"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Fortschritt"
          name="progress"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={progress}
          onChange={(e) => setProgress(e.target.value)}
          onBlur={handleUpdate}
        />
        <TextField
          label="Geburtstag"
          name="birthday"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          size="small"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          onBlur={handleUpdate}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <span>Last updated</span>{" "}
          <strong>
            <Moment format="YYYY/MM/DD HH:mm">{data.updatedAt}</Moment>
          </strong>
        </div>
      </div>
      <Divider />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell align="right">{data.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Gender
            </TableCell>
            <TableCell align="right">
              {data.profile.gender ? data.profile.gender : "-"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Sucht
            </TableCell>
            <TableCell align="right">
              {data.interested ? data.interested : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Alter
            </TableCell>
            <TableCell align="right">
              {data.birthday ? data.birthday : "-"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Größe (cm)
            </TableCell>
            <TableCell align="right">{data.size ? data.size : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Haarfarbe
            </TableCell>
            <TableCell align="right">{data.hair ? data.hair : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Augenfarbe
            </TableCell>
            <TableCell align="right">{data.eye ? data.eye : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Figur
            </TableCell>
            <TableCell align="right">
              {data.figure ? data.figure : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Raucher
            </TableCell>
            <TableCell align="right">
              {data.smoker ? data.smoker : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Suche
            </TableCell>
            <TableCell align="right">
              {data.searching ? data.searching : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Beziehungsstatus
            </TableCell>
            <TableCell align="right">
              {data.status ? data.status : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Kinder
            </TableCell>
            <TableCell align="right">
              {data.children ? data.children : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Beruf
            </TableCell>
            <TableCell align="right">{data.job ? data.job : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Sprache
            </TableCell>
            <TableCell align="right">
              {data.language ? data.language : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

function MessengerPage(props) {
  const id = props.match.params.id;
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    api.get(`${API}/channels/${id}`).then((res) => {
      setData(res.data.data);
    });
  }, [id]);
  if (!data) return <Loader />;
  return (
    <div style={{ margin: -15 }}>
      <Header data={data} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={3}>
          <Scrollbars style={{ height: "100%" }}>
            <Member data={data.member} channel={data} />
          </Scrollbars>
        </Grid>
        <Grid item xs={12} md={6}>
          <Chat member={data.member} profile={data.user} channelId={id} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Scrollbars style={{ height: "100%" }}>
            <Profile data={data.user} channel={data} />
          </Scrollbars>
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(MessengerPage);
