import React from "react";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Logo from "../Logo";
import Chatter from "./Links/Chatter";
import Admin from "./Links/Admin";
import SuperAdmin from "./Links/SuperAdmin";
import Advertiser from "./Links/Advertiser";
import Authorize from "../../utils/Authorize";
import Outs from "../Header/Outs";
import styled from "styled-components";

const OutsWrapper = styled.div`
  padding: 15px;
  padding-bottom: 0;
  div {
    width: 100%;
    margin-bottom: 5px;
  }
`;
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    paddingLeft: 25,
    ...theme.mixins.toolbar,
  },

  navIcon: {
    paddingLeft: 8,
  },
  item: {
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
}));

export default function MiniDrawer({ themeToggle, open, setOpen }) {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <div className={classes.toolbar}>
        <Logo style={{ height: 40 }} />
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <Divider />
        <OutsWrapper>
          <Outs />
        </OutsWrapper>
        <Authorize roles={[6]}>
          <SuperAdmin sidebar={open} />
        </Authorize>
        <Authorize roles={[3]}>
          <Chatter sidebar={open} />
        </Authorize>
        <Authorize roles={[4]}>
          <Advertiser sidebar={open} />
        </Authorize>
        <Authorize roles={[5]}>
          <Admin sidebar={open} />
        </Authorize>
      </div>
    </Drawer>
  );
}
