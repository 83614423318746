import React from "react";
import styled from "styled-components";

const ModalActions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 15px;
  display: flex;
  justify-content: flex-end;
`;
export default function Header({ children }) {
  return <ModalActions>{children}</ModalActions>;
}
