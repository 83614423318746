import React from "react";
import { connect } from "react-redux";
import Header from "../components/Page/Header";
function Tickets() {
  return (
    <React.Fragment>
      <Header title='Ticketcenter' />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Tickets);
