import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AiOutlineDelete } from "react-icons/ai";
import { deleteCoupon } from "../../redux/actions/couponsActions";
import { connect } from "react-redux";
function DeleteCoupon(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteCoupon = () => {
    props.deleteCoupon(props.toDelete).then(data => {
      setOpen(false);
    });
  };
  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>
        <AiOutlineDelete></AiOutlineDelete>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Delete coupon</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are u sure u want to delete this coupon! Deleting this coupon will
            delete it from all users and all it used data!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleDeleteCoupon} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default connect(state => state, { deleteCoupon })(DeleteCoupon);
