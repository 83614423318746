import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { NavLink } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
const useStyles = makeStyles((theme) => ({
  navIcon: {
    paddingLeft: 8,
    "& > svg": {
      width: 24,
      height: 24,
    },
  },
  item: {
    borderRadius: 5,
    marginBottom: 5,
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
  list: {
    padding: 15,
  },
}));
export default function Crm() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        exact={true}
        to='/'>
        <ListItemIcon className={classes.navIcon}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary='Dashboard' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        exact={true}
        to='/advertiser/users'>
        <ListItemIcon className={classes.navIcon}>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary='Users' />
      </ListItem>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/settings'>
        <ListItemIcon className={classes.navIcon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </ListItem>
    </List>
  );
}
