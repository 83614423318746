import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../redux/actions/usersActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import AddNew from "../components/Admin/AddNew";
import Delete from "../components/User/Delete";
import Edit from "../components/Admin/Edit";
import ChangePassword from "../components/User/ChangePassword";
class Team extends Component {
  static propTypes = {
    users: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
  };
  render() {
    const { users, data, loading } = this.props.users;
    const columns = [
      {
        id: "name",
        Header: "Name",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.username}</strong>,
      },
      {
        id: "email",
        Header: "Email",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>
            <a
              style={{ color: "inherit", textTransform: "lowercase" }}
              href={`mailto:${props.original.email} `}
            >
              {props.original.email}{" "}
            </a>
          </strong>
        ),
      },
      {
        id: "createdAt",
        Header: "Register date",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>
            <Moment>{props.original.createdAt}</Moment>
          </strong>
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Actions>
            <Tooltip arrow title="Change password">
              <ChangePassword userId={props.original._id} />
            </Tooltip>
            <Tooltip arrow title="Edit this user">
              <Edit data={props.original} />
            </Tooltip>
            {/* <Tooltip arrow title='Delete this user'>
              <Delete userId={props.original._id} />
            </Tooltip> */}
          </Actions>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Header title="Admins" size={data.total}>
          <AddNew />
        </Header>
        <Table
          data={users}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&type=5&${queryString}`;
            this.props.getUsers(query);
          }}
          noDataText="No admins"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUsers })(Team);
