import React from "react";
import { connect } from "react-redux";
function Authorize({ auth, roles, children }) {
  const { user, isAuthenticated, isLoading } = auth;

  if (!isLoading) {
    if (isAuthenticated && user && roles.includes(user.type)) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  } else {
    return <React.Fragment />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Authorize);
