import React from "react";
import Head from "../../components/Team/Head";
import { connect } from "react-redux";
import { getUser } from "../../redux/actions/usersActions";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import { API } from "../../config";

import Loader from "../../components/LoaderPage";
import Messages from "./messages";
import Filters from "../../components/Filters/Advertiser";
import { api } from "../../lib/api";
function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center", height: "100%" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function TeamMember(props) {
  const { data, error } = useSWR(
    `${API}/info/full/chatter/${props.match.params.id}`,
    api
  );
  if (error) return <div></div>;
  if (!data) return <Loader />;
  const user = data.data.user;
  return (
    <React.Fragment>
      <Filters />
      <Head user={user} />
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={2}>
          <Card number={data.data.totalOuts} title="Total outs" />
        </Grid>
        <Grid item xs={12} md={2}>
          <Card
            number={data.data.outsThisMonth}
            title="Total outs this month"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Card number={data.data.totalIns} title="Total ins" />
        </Grid>
        <Grid item xs={12} md={2}>
          <Card
            number={data.data.totalInsThisMonth}
            title="Total ins this month"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Card
            number={data.data.activeDialogues}
            title="Total active dialogues"
          />
        </Grid>
      </Grid>
      <h1>Latest messages</h1>
      <Messages chatter={user._id} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

export default connect(mapStateToProps, {
  getUser,
})(TeamMember);
