import React from "react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
export default function Loader({ children, loading, style }) {
  return (
    <LoadingOverlay
      active={loading}
      spinner={<BounceLoader color='#000' />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
        }),
      }}
      fadeSpeed={500}>
      {children}
    </LoadingOverlay>
  );
}
