import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Actions from "../Modal/Actions";
import Header from "../Modal/Header";
import Content from "../Modal/Content";
import { AiOutlineDelete } from "react-icons/ai";
import { connect } from "react-redux";
import { deleteUser } from "../../redux/actions/usersActions";
function DeleteUser(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    props.deleteUser(props.userId);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span className='fi' onClick={handleClickOpen}>
        <AiOutlineDelete></AiOutlineDelete>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <Header title='Delete user' />
        <Content>
          Are u sure u want to delete this user? You wont be able to recover it
          anymore!
        </Content>
        <Actions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
          <Button onClick={handleSubmit} color='primary' autoFocus>
            Delete
          </Button>
        </Actions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { deleteUser })(DeleteUser);
