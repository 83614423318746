import React from "react";
import { connect } from "react-redux";
import Channels from "../components/Messenger/Channels";
import Header from "../components/Page/Header";
function MessengerPage(props) {
  return (
    <React.Fragment>
      <Header title='My active dialogues' />
      <Channels />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(MessengerPage);
