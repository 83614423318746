import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import SidebarMobile from "../components/Sidebar/SidebarMobile";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "../components/Loader";
import { joinChatter } from "../redux/actions/socketActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    maxWidth: "1920px",
    margin: "0 auto",
    padding: "15px",
    marginTop: 64,
    minHeight: "calc(100vh - 64px)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: theme.palette.background.paper,
  },
}));

function LayoutDefault({
  children,
  setTheme,
  theme,
  auth,
  global,
  joinChatter,
}) {
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.up("lg"));
  const classes = useStyles();
  const [open, setOpen] = React.useState(matches ? true : false);
  const { isAuthenticated, isLoading, user } = auth;
  const { fetching, loading } = global;
  React.useEffect(() => {
    if (!isLoading && user && user.type == 3) {
      joinChatter(user._id);
    }
  }, []);

  return (
    <div className={classes.root}>
      {fetching && (
        <LinearProgress
          style={{
            zIndex: 999999,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
          }}
        />
      )}
      {isAuthenticated && (
        <React.Fragment>
          <Header
            setTheme={setTheme}
            theme={theme}
            open={open}
            setOpen={setOpen}
          />
          {matches ? (
            <Sidebar open={open} setOpen={setOpen} />
          ) : (
            <SidebarMobile open={open} setOpen={setOpen} />
          )}

          <Loader loading={loading}>
            <main className={classes.content}>{children}</main>
          </Loader>
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps, { joinChatter })(LayoutDefault);
