import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import SettingsIcon from "@material-ui/icons/Settings";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Switch from "@material-ui/core/Switch";
import LogOut from "../Auth/LogOut";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  drop: {
    position: "absolute",
    right: 0,
    top: 80,
    minWidth: 250,
    minHeight: 200,
  },
  avatar: {
    padding: 5,
  },
  user: {
    padding: 15,
  },
  userName: {
    display: "block",
    fontWeight: 600,
  },
  link: {
    color: theme.palette.text.primary,
  },
}));

const AvatarToggle = styled(Avatar)`
  cursor: pointer;
`;

function User({ auth, setTheme, theme }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const { user, isAuthenticated } = auth;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.wrapper}>
        <IconButton color="inherit" component="span" className={classes.avatar}>
          <AvatarToggle
            alt={user.profile.name}
            src={user.profile.picture}
            onClick={handleClick}
          />
        </IconButton>

        {open && (
          <Paper className={classes.drop}>
            <Grid container spacing={0} className={classes.user}>
              <Grid item xs={3}>
                <Avatar
                  alt={user.profile.name}
                  src={user.profile.picture}
                  onClick={handleClick}
                />
              </Grid>
              {isAuthenticated && (
                <Grid item xs={6}>
                  <span className={classes.userName}>{user.username}</span>
                  <span className={classes.userEmail}>{user.email}</span>
                </Grid>
              )}
            </Grid>
            <Divider></Divider>
            <List>
              <ListItem
                onClick={handleClickAway}
                button
                component={Link}
                to="/settings"
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText className={classes.link} primary="Settings" />
              </ListItem>
              <LogOut></LogOut>
            </List>
            <Divider></Divider>
            <List>
              <ListItem button onClick={() => setTheme(!theme)}>
                <ListItemIcon>
                  <Brightness4Icon />
                </ListItemIcon>
                <Switch color="primary" checked={theme} value="lightMode" />
                <ListItemText className={classes.link} primary="Light Mode" />
              </ListItem>
            </List>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
}
export default connect((state) => state)(User);
