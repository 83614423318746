import React from "react";
import { useNetwork } from "react-use";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function Network() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const state = useNetwork();

  React.useEffect(() => {
    if (state.online === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Your offline</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        <DialogContentText id="alert-dialog-description">
          Please connect too a internet connection to continue using the app!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Dissmis
        </Button>
      </DialogActions>
    </Dialog>
  );
}
