import React, { useState, useEffect } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { API } from "../../config";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Button from "@material-ui/core/Button";
import { api } from "../../lib/api";
const HeaderWrapper = styled.div`
  background: ${(props) => props.theme.palette.primary.main};
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;

  button {
    color: #fff;
  }
`;

const Outs = styled.div`
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 15px;
  color: #fff;
`;

const Ins = styled.div`
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 15px;
  color: #fff;
`;

const FriendsInc = styled.div`
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 10px;
  }
`;
export default function Header({ data }) {
  const [friend, setFriend] = useState(null);
  useEffect(() => {
    api
      .get(
        `${API}/users/friend-status?user=${data.member._id}&profile=${data.user._id}`
      )
      .then((res) => {
        setFriend(res.data);
      });
  }, []);

  const handleRequestAccept = () => {
    const submitData = {
      user: data.member._id,
      profile: data.user._id,
    };

    if (friend && friend.friendRequest) {
      api
        .post(
          `${API}/users/friend-accept/${friend.friendRequest._id}`,
          submitData
        )
        .then((res) => {
          window.location.reload();
        });
    }
  };

  const handleRequestDelete = () => {
    const submitData = {
      user: data.member._id,
      profile: data.user._id,
    };

    if (friend && friend.friendRequest) {
      api
        .delete(
          `${API}/users/friend-decline/${friend.friendRequest._id}`,
          submitData
        )
        .then((res) => {
          window.location.reload();
        });
    }
  };

  return (
    <HeaderWrapper>
      {friend && (
        <>
          {friend.friends ? (
            <FriendsInc>
              <PermIdentityIcon /> <span>Friends</span>
            </FriendsInc>
          ) : (
            <>
              {!friend.friends && friend.friendRequest ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleRequestAccept}
                    style={{ marginRight: 10, borderColor: "#fff" }}
                  >
                    Accept Friend Request
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleRequestDelete}
                    style={{ marginRight: 10, borderColor: "red" }}
                  >
                    Decline Friend Request
                  </Button>
                </>
              ) : (
                <FriendsInc>
                  <PermIdentityIcon /> <span>Not Friends</span>
                </FriendsInc>
              )}
            </>
          )}
        </>
      )}

      <Ins>{data.in} ins</Ins>
      <Outs>{data.out} outs</Outs>
      <IconButton>
        <TouchAppIcon />
      </IconButton>
    </HeaderWrapper>
  );
}
