import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { NavLink } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core/styles";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SettingsIcon from "@material-ui/icons/Settings";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PublicIcon from "@material-ui/icons/Public";
const useStyles = makeStyles((theme) => ({
  navIcon: {
    paddingLeft: 8,
    "& > svg": {
      width: 24,
      height: 24,
    },
  },
  item: {
    borderRadius: 5,
    marginBottom: 5,
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
  list: {
    padding: 15,
  },
}));

export default function Crm() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        exact={true}
        to='/'>
        <ListItemIcon className={classes.navIcon}>
          <DashboardIcon />
        </ListItemIcon>

        <ListItemText primary='Dashboard' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/messenger'>
        <ListItemIcon className={classes.navIcon}>
          <FaRegEnvelopeOpen />
        </ListItemIcon>
        <ListItemText primary='Active dialogues' />
      </ListItem>

      {/* <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/favorites'>
        <ListItemIcon className={classes.navIcon}>
          <MdFavoriteBorder />
        </ListItemIcon>
        <ListItemText primary='Favorites' />
      </ListItem> */}

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/global-room'>
        <ListItemIcon className={classes.navIcon}>
          <PublicIcon />
        </ListItemIcon>
        <ListItemText primary='Global Room' />
      </ListItem>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/waiting-room'>
        <ListItemIcon className={classes.navIcon}>
          <HourglassEmptyIcon />
        </ListItemIcon>
        <ListItemText primary='Waiting room' />
      </ListItem>
      <ListItem className={classes.item} button component={NavLink} to='/asa'>
        <ListItemIcon className={classes.navIcon}>
          <DoneAllIcon />
        </ListItemIcon>
        <ListItemText primary='Asa' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/settings'>
        <ListItemIcon className={classes.navIcon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </ListItem>
    </List>
  );
}
