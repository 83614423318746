import {
  GET_COINS,
  GET_COIN,
  ADD_COIN,
  COINS_LOADING,
  DELETE_COIN,
  UPDATE_COIN,
} from "../types";
import cogoToast from "cogo-toast";
import { API } from "../../config";
import { returnErrors } from "./errorActions";
import { api } from "../../lib/api";

export const getCoins = (query) => (dispatch) => {
  dispatch(setCoinsLoading());
  api
    .get(`${API}/coin-packs${query}`)
    .then((res) =>
      dispatch({
        type: GET_COINS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getCoin = (id) => {
  return (dispatch) => {
    api
      .get(`${API}/coin-packs/${id}`)
      .then((response) => {
        dispatch({
          type: GET_COIN,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          dispatch(returnErrors(error.response.data, error.response.status))
        );
      });
  };
};

export const addCoin = (data) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    api
      .post(`${API}/coin-packs`, data)
      .then((response) => {
        dispatch({
          type: ADD_COIN,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });
export const updateCoinPack = (id, data, formikApi) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    api
      .put(`${API}/coin-packs/${id}`, data)
      .then((response) => {
        dispatch({
          type: UPDATE_COIN,
          payload: response.data,
        });
        formikApi.setSubmitting(false);
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        formikApi.setSubmitting(false);
        reject(error);
      });
  });

export const deleteCoin = (id) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    api
      .delete(`${API}/coin-packs/${id}`)
      .then((response) => {
        dispatch({
          type: DELETE_COIN,
          payload: id,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });

export const setCoinsLoading = () => {
  return {
    type: COINS_LOADING,
  };
};
