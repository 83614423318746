import {
  GET_CHANNELS,
  CHANNELS_LOADING,
  ADD_MESSAGE,
  GET_MESSAGES,
  ADD_OUTS,
  MESSAGES_LOADING,
  GET_MORE_MESSAGES,
} from "../types";
import cogoToast from "cogo-toast";
import { returnErrors } from "./errorActions";
import { API } from "../../config";
import { api } from "../../lib/api";
export const getChannels = (query) => (dispatch, getState) => {
  dispatch(setChannelsLoading());
  api
    .get(`${API}/channels${query}`)
    .then((res) =>
      dispatch({
        type: GET_CHANNELS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const checkChannel = (query) => (dispatch) => {
  dispatch(setChannelsLoading());
  api
    .get(`${API}/channels${query}`)
    .then((res) =>
      dispatch({
        type: GET_CHANNELS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const sendMessage = (data) => (dispatch, getState) => {
  api
    .post(`${API}/messages`, data)
    .then((res) => {
      dispatch({
        type: ADD_MESSAGE,
        payload: res.data,
      });
      dispatch({
        type: ADD_OUTS,
        payload: 1,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getMessages = (member, user) => (dispatch, getState) => {
  dispatch(setMessagesLoading());

  api
    .get(`${API}/messages?member=${member}&user=${user}`)
    .then((res) =>
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getMoreMessages =
  (member, user, page, setLoadingMore) => (dispatch, getState) => {
    setLoadingMore(true);

    api
      .get(`${API}/messages?member=${member}&user=${user}&page=${page}`)
      .then((res) => {
        setLoadingMore(false);
        dispatch({
          type: GET_MORE_MESSAGES,
          payload: res.data,
        });
      })
      .catch((err) => {
        setLoadingMore(false);
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const getMessagesChatter = (query) => (dispatch, getState) => {
  dispatch(setMessagesLoading());
  api
    .get(`${API}/messages${query}`)
    .then((res) =>
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const takeUser = (channel, chatter, setOpen) => (dispatch, getState) => {
  api
    .put(`${API}/channels/take/${channel}/${chatter}`)
    .then((res) => {
      cogoToast.success("Client has been moved to your open dialogues", {
        position: "top-right",
        heading: "Client has been moved",
      });
      setOpen(false);
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addMessage = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE,
    payload: message,
  });
};

export const setChannelsLoading = () => {
  return {
    type: CHANNELS_LOADING,
  };
};

export const setMessagesLoading = () => {
  return {
    type: MESSAGES_LOADING,
  };
};
