import {
  GET_ACTIVITY,
  ACTIVITY_LOADING,
  GET_COINS_ACTIVITY,
  COINS_ACTIVITY_LOADING
} from "../types";

const initialState = {
  activities: [],
  data: [],
  loading: false,
  coins: [],
  coins_data: [],
  coins_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITY:
      return {
        ...state,
        activities: action.payload.data,
        data: action.payload,
        loading: false,
      };
    
    case ACTIVITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_COINS_ACTIVITY:
      return {
        ...state,
        coins: action.payload.data,
        coins_data: action.payload,
        coins_loading: false,
      };
    
    case COINS_ACTIVITY_LOADING:
      return {
        ...state,
        coins_loading: true,
      };
    default:
      return state;
  }
}
