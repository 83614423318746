import React, { Component } from "react";
import { connect } from "react-redux";
import { getCoupons } from "../redux/actions/couponsActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import AddNew from "../components/Coupons/AddNew";
import Delete from "../components/Coupons/Delete";
import Moment from "react-moment";
class Coupons extends Component {
  render() {
    const { coupons, data, loading } = this.props.coupon;
    const columns = [
      {
        id: "title",
        Header: "Title",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong style={{ textTransform: "lowercase" }}>
            {props.original.title}
          </strong>
        ),
      },
      {
        id: "coins",
        Header: "Coins",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.coins}</strong>,
      },
      {
        id: "used",
        Header: "Used",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>{props.original.used ? props.original.used : "0"}</strong>
        ),
      },
      {
        id: "expirationDate",
        Header: "Expiration Date",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>
            <Moment>{props.original.expirationDate}</Moment>
          </strong>
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Actions>
            <Tooltip arrow title='Delete this coupon'>
              <Delete toDelete={props.original._id} />
            </Tooltip>
          </Actions>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Header title='Coupons' size={data.total}>
          <AddNew />
        </Header>
        <Table
          data={coupons}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&${queryString}`;
            this.props.getCoupons(query);
          }}
          noDataText='No Coupons'
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  coupon: state.coupon,
});

export default connect(mapStateToProps, { getCoupons })(Coupons);
