import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import createSocketIoMiddleware from "redux-socket.io";
import io from "socket.io-client";
import { APIROOT } from "../config";
let socket = io(APIROOT, { transports: ["websocket"] });
let socketIoMiddleware = createSocketIoMiddleware(socket, "server/");
const initialState = {};

const middleWare = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(socketIoMiddleware, ...middleWare))
);

socket.on("disconnect", function () {
  store.dispatch({ type: "socketStatus", data: "Disconnected" });
});

socket.on("connect", function () {
  store.dispatch({ type: "socketStatus", data: "Connected" });
});

socket.on("reconnect", function () {
  store.dispatch({ type: "socketStatus", data: "Reconnecting..." });
});

export default store;
