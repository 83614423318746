import React from "react";
import styled from "styled-components";
import MessageInput from "./MessageInput";
import Content from "./Content";
import { connect } from "react-redux";
import { addMessage } from "../../../redux/actions/messengerActions";
import { joinChannel, chatMessage } from "../../../redux/actions/socketActions";
const ChatWrapper = styled.div`
  position: relative;
  height: calc(100vh - 126px);
  width: 100%;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.palette.divider};
`;

function Chat(props) {
  const [typing, setTyping] = React.useState(false);
  React.useEffect(() => {
    // Join channel
    props.joinChannel(props.channelId);
  }, []);

  const sendMessage = (message) => {
    props.chatMessage(message);
  };

  const { member, profile } = props;
  return (
    <ChatWrapper>
      <Content profile={profile} member={member} />
      <MessageInput
        profile={profile}
        member={member}
        typing={typing}
        onSubmit={sendMessage}
        channelId={props.channelId}
      />
    </ChatWrapper>
  );
}

export default connect((state) => state, {
  addMessage,
  joinChannel,
  chatMessage,
})(Chat);
