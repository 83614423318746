/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { V1 } from '../../lib/api'
import { useDebounce } from 'use-debounce';

export default function Asynchronous({ selected, setSelected, filters }) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [search, setSearch] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [value] = useDebounce(search, 500);

    React.useEffect(() => {
        if (open) {
            (async () => {
                setLoading(true)
                V1.get('/users/all', {
                    params: {
                        ...filters,
                        name: value
                    }
                }).then(res => {
                    setLoading(false)
                    setOptions(res.data.data);
                })
            })();
        }
    }, [open, value]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            style={{ marginBottom: 15 }}
            inputValue={search}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === 'reset') {
                    setSearch('')
                    return
                } else {
                    setSearch(newInputValue)
                }
            }}
            onChange={(e, value) => {
                setSearch('')
                if (value) {
                    setSelected([...selected, value])
                } else {
                    setSelected([...selected])
                }
            }}
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.username === value.username}
            getOptionDisabled={(option) => {
                if (selected.find(item => item.username == option.username)) {
                    return true
                }
                return false
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.username}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}