import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { NavLink } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core/styles";
import PaymentIcon from "@material-ui/icons/Payment";
import { RiCoupon2Line } from "react-icons/ri";
import { AiOutlineGift } from "react-icons/ai";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SettingsIcon from "@material-ui/icons/Settings";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AllInboxIcon from '@material-ui/icons/AllInbox';
const useStyles = makeStyles((theme) => ({
  navIcon: {
    paddingLeft: 8,
    "& > svg": {
      width: 24,
      height: 24,
    },
  },
  item: {
    borderRadius: 5,
    marginBottom: 5,
    "&.active": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
  list: {
    padding: 15,
  },
}));

export default function Crm() {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        exact={true}
        to='/'>
        <ListItemIcon className={classes.navIcon}>
          <DashboardIcon />
        </ListItemIcon>

        <ListItemText primary='Dashboard' />
      </ListItem>

      <List component='div'>
        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          exact={true}
          to='/super-admins'>
          <ListItemText primary='Super Admins' />
        </ListItem>
        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          exact={true}
          to='/admins'>
          <ListItemText primary='Admins' />
        </ListItem>
        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          exact={true}
          to='/clients'>
          <ListItemText primary='Clients' />
        </ListItem>
        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          exact={true}
          to='/chatters'>
          <ListItemText primary='Chatters' />
        </ListItem>

        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          to='/profiles'>
          <ListItemText primary='Profiles' />
        </ListItem>
        <ListItem
          className={classes.item}
          style={{ paddingLeft: 70 }}
          button
          component={NavLink}
          to='/advertising'>
          <ListItemText primary='Advertising' />
        </ListItem>
      </List>

      <ListItem className={classes.item} button component={NavLink} to='/coins'>
        <ListItemIcon className={classes.navIcon}>
          <PaymentIcon />
        </ListItemIcon>

        <ListItemText primary='Coins' />
      </ListItem>
      <ListItem className={classes.item} button component={NavLink} to='/gifts'>
        <ListItemIcon className={classes.navIcon}>
          <AiOutlineGift />
        </ListItemIcon>
        <ListItemText primary='Gifts' />
      </ListItem>
      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/coupons'>
        <ListItemIcon className={classes.navIcon}>
          <RiCoupon2Line />
        </ListItemIcon>

        <ListItemText primary='Coupons' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/admin/dialogues'>
        <ListItemIcon className={classes.navIcon}>
          <FaRegEnvelopeOpen />
        </ListItemIcon>
        <ListItemText primary='Active dialogues' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/waiting-room'>
        <ListItemIcon className={classes.navIcon}>
          <HourglassEmptyIcon />
        </ListItemIcon>
        <ListItemText primary='Waiting room' />
      </ListItem>

      <ListItem className={classes.item} button component={NavLink} to='/asa'>
        <ListItemIcon className={classes.navIcon}>
          <DoneAllIcon />
        </ListItemIcon>
        <ListItemText primary='Asa' />
      </ListItem>

      <ListItem className={classes.item} button component={NavLink} to='/spam-sender'>
        <ListItemIcon className={classes.navIcon}>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText primary='Spam' />
      </ListItem>

      <ListItem className={classes.item} button component={NavLink} to='/spam-room'>
        <ListItemIcon className={classes.navIcon}>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText primary='Spam room' />
      </ListItem>


      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/payments'>
        <ListItemIcon className={classes.navIcon}>
          <CreditCardIcon />
        </ListItemIcon>
        <ListItemText primary='Payments' />
      </ListItem>

      <ListItem
        className={classes.item}
        button
        component={NavLink}
        to='/settings'>
        <ListItemIcon className={classes.navIcon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </ListItem>
    </List>
  );
}
