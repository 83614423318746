import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
export default function Head({ user }) {
  return (
    <Paper style={{ padding: 30 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Avatar
            src={user && user.profile.picture}
            alt={user && user.profile.name}
            style={{ width: 180, height: 180 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}>
          <h1 style={{ margin: 0, marginBottom: 15 }}>
            {user && user.profile.name}{" "}
          </h1>
          <h3 style={{ margin: 0 }}> {user && user.email}</h3>
        </Grid>
      </Grid>
    </Paper>
  );
}
