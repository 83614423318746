const initialState = {
  socket: null,
  socketStatus: "Disconnected",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "server/joinChatter":
      return {
        type: "server/joinChannel",
        data: action.data,
      };
    case "server/joinChannel":
      return {
        type: "server/joinChannel",
        data: action.data,
      };
    case "server/chatMessage":
      return {
        type: "server/chatMessage",
        data: action.data,
      };

    default:
      return state;
  }
}
