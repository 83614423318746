export const joinChatter = (id) => {
  return {
    type: "server/joinChatter",
    data: id,
  };
};
export const joinChannel = (id) => {
  return {
    type: "server/joinChannel",
    data: id,
  };
};
export const chatMessage = (message) => {
  return {
    type: "server/chatMessage",
    data: message,
  };
};
