import React, { Component } from "react";
import { connect } from "react-redux";
import { getCoinsActivity } from "../../redux/actions/activityActions";
import Table from "../../components/Table";
import Moment from "react-moment"
import Card from "../../components/Card"
class Activity extends Component {
  render() {
    const { coins, coins_data, coins_loading } = this.props.activity;
    const columns = [
      {
        id: "title",
        Header: "Title",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>
            {props.original.title}
          </React.Fragment>
        ),
      },
      {
        id: "profile",
        Header: "Profile",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>
            {props.original.profileId && props.original.profileId.username}
          </React.Fragment>
        ),
      },
      {
        id: "chatter",
        Header: "Chatter",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>
            {props.original.chatterId && props.original.chatterId.username}
          </React.Fragment>
        ),
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>
            -{props.original.amount}
          </React.Fragment>
        ),
      },


      {
        id: "date",
        Header: "Date",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Moment format='DD-MM-YYYY HH:mm'>
            {props.original.createdAt}
          </Moment>
        ),
      },
    ];
    return (
      <Card title="Coins Activity" style={{marginTop: 15}}>
        <Table
          data={coins}
          columns={columns}
          loading={coins_loading}
          page={!coins_data.pagination ? 0 : coins_data.pagination.current - 1}
          pages={!coins_data.pagination ? 1 : coins_data.pagination.total}
          onFetchData={(state) => {
            let query = `?page=${state.page + 1}&member=${this.props.userId}`;
            this.props.getCoinsActivity(query);
          }}
          noDataText='No activity'
          minRows={10}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: state.activity,
});

export default connect(mapStateToProps, { getCoinsActivity })(Activity);
