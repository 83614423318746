import React from "react";
import styled from "styled-components";

const ModalHeader = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 0 15px;
  h1 {
    margin: 0;
    line-height: 60px;
  }
`;
export default function Header({ title }) {
  return (
    <ModalHeader>
      <h1>{title}</h1>
    </ModalHeader>
  );
}
