import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @media (max-width: 960px) {
    justify-content: center;
    padding-right: 0;
  }
`;
const Title = styled(Grid)`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;
export default function Card(props) {
  return (
    <Paper style={props.style}>
      <Grid container spacing={0}>
        <Title item xs={12} md={props.button ? 4 : 12}>
          <h3 style={{ margin: 0, lineHeight: "58px", paddingLeft: 15 }}>
            {props.title}
          </h3>
        </Title>
        {props.button && (
          <ButtonWrapper item xs={12} md={8}>
            {props.button}
          </ButtonWrapper>
        )}
      </Grid>
      <Divider />
      <Grid container spacing={2} style={props.contentStyles}>
        <Grid item xs={12} md={12}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
}
