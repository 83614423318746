import React from "react";
import { connect } from "react-redux";
import { getSentGifts } from "../../redux/actions/giftsActions";
import Table from "../Table";
import Card from "../Card";
import Moment from "react-moment";
function SentGifts(props) {
  const { sentGifts, sentGiftsData, loading } = props.gift;
  const columns = [
    {
      id: "image",
      Header: "Gift image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <img
          src={props.original.gift && props.original.gift.image}
          alt={props.original.gift && props.original.gift.title}
          style={{ height: 50 }}
        />
      ),
    },
    {
      id: "title",
      Header: "Gift title",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.gift && props.original.gift.title}</strong>
      ),
    },
    {
      id: "price",
      Header: "Gift Price",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.gift && props.original.gift.coins}</strong>
      ),
    },
    {
      id: "sentDate",
      Header: "Sent Date",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "sentTo",
      Header: "Sent to",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.to && props.original.to.profile.name}</strong>
      ),
    },
  ];

  return (
    <Card title='Sent Gifts' style={{ marginTop: 15 }}>
      <Table
        data={sentGifts}
        columns={columns}
        loading={loading}
        page={
          !sentGiftsData.pagination ? 0 : sentGiftsData.pagination.current - 1
        }
        pages={!sentGiftsData.pagination ? 1 : sentGiftsData.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&from=${
            props.userId
          }&${queryString}`;
          props.getSentGifts(query);
        }}
        noDataText='No sent gifts'
        minRows={5}
      />
    </Card>
  );
}

const mapStateToProps = (state) => ({
  gift: state.gift,
});

export default connect(mapStateToProps, { getSentGifts })(SentGifts);
